import { Recap } from '../YearlyRecap.types'
import { Intl } from '../../../localization/react'

import findMostActiveMonth from './find-most-active-month'

interface Args {
  recap: Recap
  intl: Intl
}

function getRecapSectionSubtitles({ recap, intl }: Args) {
  const { activeDays, minutesActive, sports, sportsCount, venues, friends, trainers } = recap

  const topSport = sports[0].sport
  const topVenue = venues[0].venue
  const topFriend = friends.length ? friends[0].friend.publicUser : undefined
  const topTrainer = trainers.length ? trainers[0].trainer : undefined

  const mostActiveMonth = findMostActiveMonth(recap)

  const activeDaysText = (() => {
    if (activeDays <= 25) return intl.yearlyRecapActiveDaysSubtitleTier1()
    if (activeDays <= 51) return intl.yearlyRecapActiveDaysSubtitleTier2()
    if (activeDays <= 103) return intl.yearlyRecapActiveDaysSubtitleTier3()
    if (activeDays <= 155) return intl.yearlyRecapActiveDaysSubtitleTier4()
    return intl.yearlyRecapActiveDaysSubtitleTier5()
  })()

  const minutesActiveText = (() => {
    if (minutesActive < 2000) return intl.yearlyRecapMinutesActiveSubtitleTier1()
    if (minutesActive <= 4000) return intl.yearlyRecapMinutesActiveSubtitleTier2()
    if (minutesActive <= 8000) return intl.yearlyRecapMinutesActiveSubtitleTier3()
    if (minutesActive <= 12000) return intl.yearlyRecapMinutesActiveSubtitleTier4()
    return intl.yearlyRecapMinutesActiveSubtitleTier5()
  })()

  const sportsCountText = (() => {
    if (sportsCount === 1) return intl.yearlyRecapSportsCountSubtitleTier1({ topSportName: topSport.name })
    if (sportsCount <= 5) return intl.yearlyRecapSportsCountSubtitleTier2()
    if (sportsCount <= 10) return intl.yearlyRecapSportsCountSubtitleTier3()
    if (sportsCount <= 14) return intl.yearlyRecapSportsCountSubtitleTier4()
    return intl.yearlyRecapSportsCountSubtitleTier5()
  })()

  const topSportText = intl.yearlyRecapTopSportSubtitle({ sportName: topSport.name })
  const favoriteVenueText = intl.yearlyRecapFavouriteVenueSubtitle({ venueName: topVenue.name })

  // conditional texts
  const topFriendText = topFriend ? intl.yearlyRecapTopFriendSubtitle({ friendName: topFriend.firstName }) : ''
  const topTrainerText = topTrainer ? intl.yearlyRecapTopTeacherSubtitle({ teacherNickname: topTrainer.nickname }) : ''
  const streakText = intl.yearlyRecapStreakSubtitle()

  const mostActiveMonthName = intl.monthOfTheYear({ month: mostActiveMonth.month })
  const mostActiveMonthText = intl.yearlyRecapMostActiveMonthSubtitle({
    mostActiveMonthName,
    mostActiveMonthActivityCount: mostActiveMonth.activityCount,
  })

  return {
    activeDaysText,
    minutesActiveText,
    sportsCountText,
    topSportText,
    favoriteVenueText,
    mostActiveMonthText,
    topFriendText,
    topTrainerText,
    streakText,
  }
}

export default getRecapSectionSubtitles
