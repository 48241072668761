import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import * as React from 'react'

interface Props {
  index: number
  name: string
  count: number
  image?: React.ReactNode
}

const RecapSectionListItem = ({ index, name, count, image }: Props) => {
  return (
    <Stack
      component="li"
      direction="row"
      alignItems="center"
      sx={{
        paddingX: { xs: 2, md: 4 },
        paddingY: { xs: 1, md: 2 },
        borderBottom: '1px solid',
        borderColor: 'mediumgray',
        textAlign: 'left',
        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.50)',
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2} width="100%">
        <Stack direction="row" alignItems="center" gap={2}>
          <Text
            sx={{
              fontSize: { xs: 14, md: 16 },
              width: '14px', // avoid different width of numbers
              fontWeight: '500',
              color: 'white',
              fontFamily: "'Bricolage Grotesque', 'Adjusted Bricolage Grotesque Fallback'",
            }}
          >
            {index}.
          </Text>
          {image && image}
          <Text
            sx={{
              fontSize: { xs: 14, md: 16 },
              fontWeight: '500',
              color: 'white',
              fontFamily: "'Bricolage Grotesque', 'Adjusted Bricolage Grotesque Fallback'",
            }}
          >
            {name}
          </Text>
        </Stack>
        <Text
          sx={{
            fontSize: { xs: 14, md: 16 },
            fontWeight: '500',
            color: 'white',
            fontFamily: "'Bricolage Grotesque', 'Adjusted Bricolage Grotesque Fallback'",
          }}
        >
          {count}
        </Text>
      </Stack>
    </Stack>
  )
}

export default RecapSectionListItem
