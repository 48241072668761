import * as React from 'react'
import { AnimatePresence } from '@eversports/klimt-utilities/framer-motion'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Search from '../../components/Search/Search'

import ActivityDetails from './ActivityDetails'

const ActivityDetailsPage = () => {
  return (
    <>
      <Header>
        <Search />
      </Header>
      <AnimatePresence mode="wait">
        <ActivityDetails />
      </AnimatePresence>
      <Footer />
    </>
  )
}

export default ActivityDetailsPage
