import { Recap, RecapMonth } from '../YearlyRecap.types'

function findMostActiveMonth(recap: Recap): RecapMonth {
  return recap.activitiesPerMonth.reduce(
    (maxMonth, currentMonth) => (currentMonth.activityCount > maxMonth.activityCount ? currentMonth : maxMonth),
    recap.activitiesPerMonth[0],
  )
}

export default findMostActiveMonth
