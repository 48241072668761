import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Icon from '@eversports/klimt-primitives/Icon'
import ArrowDropDownIcon from '@eversports/design-tokens/assets/icons/arrow-drop-down.svg'
import Box from '@eversports/klimt-primitives/Box'
import type { JSX } from 'react'

interface Props {
  label: JSX.Element
  handleClick: (event: React.MouseEvent<HTMLElement>) => void
  valuesSelected?: Array<unknown>
}

const MobileFilter = ({ label, handleClick, valuesSelected }: Props) => {
  const hasValuesSelected = Boolean(valuesSelected?.length)

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      onClick={handleClick}
      gap={2}
      sx={{
        flex: '1 1 auto',
        border: '1px solid',
        borderColor: hasValuesSelected ? 'primary.main' : 'mediumgray',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        borderRadius: 1,
        py: 2,
        px: { xs: 3, sm: 5 },
        cursor: 'pointer',
        '&:hover': {
          borderColor: 'primary.main',
        },
      }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Text variant="small">{label}</Text>
        {hasValuesSelected && <Text sx={{ color: 'primary.main' }}>+{valuesSelected?.length}</Text>}
      </Stack>
      <Box display={{ xs: hasValuesSelected ? 'none' : 'block', sm: 'block' }}>
        <Icon src={ArrowDropDownIcon} size="default" color="gray" />
      </Box>
    </Stack>
  )
}

export default MobileFilter
