import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import type { JSX } from 'react'

import { Localized } from '../localization/react'

interface Props {
  label?: JSX.Element
}

const NewBadge = ({ label }: Props) => {
  return (
    <Text
      sx={{
        color: 'white',
        padding: 0.5,
        borderRadius: 0.5,
        backgroundColor: 'red',
        textTransform: 'uppercase',
        fontSize: '9px',
        lineHeight: '11px',
        fontWeight: 'bold',
      }}
    >
      {label || <Localized id="navigation-tab-new-tag" />}
    </Text>
  )
}

export default NewBadge
