import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'

interface Props {
  src: string
}

const RecapOverviewImage = ({ src }: Props) => {
  return (
    <Box width="56px" height="56px" padding={2} display="flex" justifyContent="center">
      <img src={src} width="24px" height="24px" loading="lazy" />
    </Box>
  )
}

export default RecapOverviewImage
