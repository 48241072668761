import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import type { JSX } from 'react'

interface Props {
  iconAdornment: JSX.Element
  label: string
}

const InputLabel = ({ iconAdornment, label }: Props) => (
  <Stack direction="row" alignItems="center" gap={2}>
    {iconAdornment}
    <Text variant="label" sx={{ fontSize: '18px', color: 'midnight' }}>
      {label}
    </Text>
  </Stack>
)

export default InputLabel
