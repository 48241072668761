import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'

const BannerMessageContainer = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <Stack
      py={6}
      px={[6, 12]}
      gap={2}
      textAlign="center"
      justifyContent="center"
      alignItems="center"
      width="100%"
      sx={{
        backgroundColor: 'rgba(20, 20, 20, 0.5)',
        minHeight: '150px',
      }}
    >
      {children}
    </Stack>
  )
}

export default BannerMessageContainer
