import { motion } from '@eversports/klimt-utilities/framer-motion'
import { List } from '@eversports/klimt-primitives/List'
import * as React from 'react'

import RecapSectionListItem from './RecapSectionListItem'
import { RecapSectionImage } from './RecapSectionImage'

export interface ListItem {
  name: string
  count: number
  imageSrc?: string
}

export type ListType = 'person' | 'venue' | 'sport'

interface Props {
  items: Array<ListItem>
  type: ListType
}

export default function RecapList({ items, type }: Props) {
  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
      },
    },
  }

  return (
    <List sx={{ width: '100%', gap: 6, px: 2 }}>
      {items.map((item, index) => {
        const image = item.imageSrc ? <RecapSectionImage type={type} src={item.imageSrc} variant="small" /> : null
        return (
          <motion.div key={`${index + 1}-${item.name}`} variants={itemVariants}>
            <RecapSectionListItem index={index + 1} name={item.name} count={item.count} image={image} />
          </motion.div>
        )
      })}
    </List>
  )
}
