import * as React from 'react'
import { ChangeEvent, useState, type JSX } from 'react'
import TextField from '@eversports/klimt-primitives/TextField'
import CircularProgress from '@eversports/klimt-primitives/CircularProgress'
import Box from '@eversports/klimt-primitives/Box'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'
import Stack from '@eversports/klimt-primitives/Stack'

import { SearchPosition } from '../Search.types'
import { borderRadiusMapping, borderWidthMapping, openDropdownBorderRadiusMapping } from '../Search.constants'
import { SEARCH_BUTTON_DIAMETER } from '../SearchButton/SearchButtonComponent'

import InputLabel from './InputLabel'

interface Props {
  searchPosition: SearchPosition
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  isLoading?: boolean
  handleFocus: () => void
  handleClick: () => void
  isOpen: boolean
  placeholder: string
  iconAdornment: JSX.Element
  inputLabel?: string
  hasSearchButton?: boolean
}

const SearchInput = ({
  searchPosition,
  searchValue = '',
  setSearchValue,
  isLoading,
  handleFocus,
  isOpen,
  placeholder,
  iconAdornment,
  inputLabel,
  handleClick,
  hasSearchButton,
}: Props) => {
  const [hidePlaceholder, setHidePlaceholder] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const label = inputLabel ? (
    <Stack direction="row" gap={4} alignItems="center">
      <InputLabel label={inputLabel} iconAdornment={iconAdornment} />
      {isLoading && !isMobile && <CircularProgress size={16} />}
    </Stack>
  ) : undefined

  const inputIcon = (
    <Box flexShrink={0} marginRight={2}>
      {isLoading ? <CircularProgress size={12} /> : iconAdornment}
    </Box>
  )

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const onFocus = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    if (isMobile) return
    setHidePlaceholder(true)
    e.target.select()
    handleFocus()
  }

  const onBlur = () => setHidePlaceholder(false)

  return (
    <TextField
      id={`search-input-${searchPosition}`}
      label={label}
      value={searchValue}
      placeholder={hidePlaceholder ? '' : placeholder}
      onClick={handleClick}
      onChange={handleInputChange}
      onFocus={onFocus}
      onBlur={onBlur}
      InputProps={{
        readOnly: isMobile ? true : false,
        startAdornment: !inputLabel ? inputIcon : undefined,
        style: { fontSize: 16 },
      }}
      InputLabelProps={{ shrink: Boolean(inputLabel) }}
      fullWidth
      autoComplete="off"
      sx={{
        '& .MuiInputBase-root': {
          height: inputLabel ? '72px' : 'initial',
          paddingLeft: { sm: 4 },
          backgroundColor: 'white',
          borderRadius:
            isOpen && !isMobile ? openDropdownBorderRadiusMapping[searchPosition] : borderRadiusMapping[searchPosition],
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: isOpen ? '1px' : borderWidthMapping[searchPosition],
          },
        },
        '& .MuiInputBase-input': {
          marginTop: inputLabel ? 6 : '0px',
          marginRight: { sm: hasSearchButton ? `calc(${SEARCH_BUTTON_DIAMETER}px + 16px)` : 0 },
        },
        '& .MuiInputBase-input::placeholder': {
          color: 'darkgray',
          WebkitTextFillColor: '#4d4d4d',
          opacity: 1,
        },
        '& .MuiFormLabel-root': {
          top: '24px',
          paddingLeft: { sm: 5 },
        },
        '& legend': {
          display: 'none',
        },
        '& fieldset': {
          top: 0,
        },
        '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
            borderWidth: '1px',
          },
        },
      }}
    />
  )
}

export default SearchInput
