import * as React from 'react'
import { useEffect } from 'react'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'
import { useParams } from 'react-router-dom'

import { useProfilePublicUserQuery } from '../../../../graphql'
import LoadingPage from '../../../../components/LoadingPage'
import { NotFoundError } from '../../../../App.types'
import { userTypeMapping } from '../../PublicUserProfile.constants'
import { fromMapping } from '../../../../App.constants'
import { UserType } from '../../PublicUserProfile.types'
import useLoggedInUserType from '../../hooks/use-logged-in-user-type'
import useFromParam from '../../../../hooks/use-from-param'

import ProfileComponent from './ProfileComponent'
import PrivateProfile from './PrivateProfile'

const Profile = () => {
  const { amplitude, logAmplitudeEventOnce } = useLogAmplitudeEventOnce()
  const { userType } = useLoggedInUserType()
  const { userHandle } = useParams<{ userHandle: string }>()
  const from = useFromParam()

  const { data, loading, error } = useProfilePublicUserQuery({
    variables: { handle: userHandle },
  })

  useEffect(() => {
    if (!error) return

    if (error.graphQLErrors.some(({ extensions }) => extensions?.status === 404)) {
      throw new NotFoundError(`User ${userHandle} was not found`, error.message)
    }

    throw new Error(error.graphQLErrors.map(({ message }) => message).join(' '))
  }, [error])

  useEffect(() => {
    if (!amplitude || !data) return
    logAmplitudeEventOnce({
      eventName: 'Viewed User Profile',
      eventProperties: {
        userType: userTypeMapping[userType],
        from: from ? fromMapping[from] : null,
      },
    })
  }, [amplitude, data])

  if (!data || loading) {
    return <LoadingPage />
  }

  if (data.publicUser.isPrivate && userType !== UserType.PROFILE_OWNER) {
    return <PrivateProfile />
  }

  return <ProfileComponent user={data.publicUser} />
}

export default Profile
