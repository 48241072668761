import * as React from 'react'
import { useEffect } from 'react'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import Stack from '@eversports/klimt-primitives/Stack'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'

import { Venue } from '../VenueProfile.types'
import { MobileSidebar } from '../Sidebar'
import { Meta, VenueOfferingType } from '../../../App.types'
import MetaHelmet from '../../../components/MetaHelmet'
import getCanonicalHref from '../../../helpers/get-canonical-href'
import getAggregateRatingStructuredData from '../../../helpers/get-aggregate-rating-structured-data'
import { useIntl } from '../../../localization/react'

import InformationBanner from './InformationBanner'
import Overview from './Overview'
import Trainers from './Trainers'
import Reviews from './Reviews'
import AdditionalInfo from './AdditionalInfo'

const MAX_SPORTS_FOR_META_DESCRIPTION = 3

interface Props {
  venue: Venue
  meta: Meta
  headerOffset: number
}

const VenueOverview = ({ venue, meta, headerOffset }: Props) => {
  const { amplitude } = useAmplitude()
  const { origin, tld } = useSettingsContext()
  const intl = useIntl()

  const listOfSportNames = venue.sports
    .slice(0, MAX_SPORTS_FOR_META_DESCRIPTION)
    .map((sport) => sport.name)
    .join(', ')

  const metaTitle =
    !venue.address || venue.hideAddress
      ? intl.venueProfileOnlineMetaTitle({ venueName: venue.name })
      : intl.venueProfileMetaTitle({ venueName: venue.name, cityName: venue.location.city, zip: venue.location.zip })

  const metaDescription = intl.venueProfileMetaDescription({ venueName: venue.name, listOfSportNames })

  const aggregateReview = getAggregateRatingStructuredData({ venue })

  const canonicalHref = getCanonicalHref({
    path: `/s/${venue.slug}`,
    origin,
    tld,
    venueCountry: venue.location.country,
  })

  const showTrainers = venue.offerings.includes(VenueOfferingType.VENUE_OFFERING_TEAM) && venue.trainers.length > 0
  const filteredBranches = venue.company.venues.filter((companyVenue) => companyVenue.id !== venue.id)
  const faqTypes = venue.faqs.map((faq) => faq.question)

  useEffect(() => {
    if (!amplitude) return

    const isBookable = venue.offerings.length > 0
    const hasMap = Boolean(venue.address || venue.location)

    amplitude.logEvent('Viewed Venue Profile Overview', {
      venue: {
        name: venue.name,
        slug: venue.slug,
        numberOfBranches: filteredBranches.length,
        hasMap,
        isBookable,
        rating: venue.rating,
        reviewCount: venue.reviewCount,
        numberOfImages: venue.images.length,
        faqs: faqTypes,
      },
    })
  }, [amplitude])

  return (
    <>
      <MetaHelmet
        meta={{ ...meta, title: metaTitle, description: metaDescription }}
        canonicalHref={canonicalHref}
        structuredDataJSONLD={aggregateReview}
      />
      <Stack gap={{ xs: 2, md: 4 }} sx={{ width: '100%' }}>
        <InformationBanner venue={venue} />
        <MobileSidebar venue={venue} />
        <Overview
          about={venue.about.general}
          address={venue.address}
          latitude={venue.location.latitude}
          longitude={venue.location.longitude}
          amenities={venue.amenities}
          headerOffset={headerOffset}
          hideAddress={venue.hideAddress}
          branches={filteredBranches}
        />
        {showTrainers && <Trainers trainers={venue.trainers} venue={venue} />}
        <Reviews
          rating={venue.rating}
          reviewCount={venue.reviewCount}
          ratings={venue.ratings}
          reviews={venue.reviews}
          headerOffset={headerOffset}
          id={venue.id}
        />
        <AdditionalInfo about={venue.about} faqs={venue.faqs} sports={venue.sports} venueName={venue.name} />
      </Stack>
    </>
  )
}

export default VenueOverview
