import * as React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useHttpStatus } from '@eversports/react-app-base/http-status/use-http-status'
import { extractStatusCodeFromError } from '@eversports/react-app-base/create-apollo-client'

import { useVenueProfileVenueContextQuery } from '../../graphql'
import LoadingPage from '../../components/LoadingPage'
import { NotFoundError } from '../../App.types'

import VenueProfileComponent from './VenueProfileComponent'

const VenueProfile = () => {
  const { venueSlug } = useParams<{ venueSlug: string }>()
  const { setStatus } = useHttpStatus()

  const { data, loading, error } = useVenueProfileVenueContextQuery({
    variables: {
      slug: venueSlug,
    },
  })

  if (error) {
    const status = extractStatusCodeFromError(error)
    if (status) {
      setStatus(status)
    }
  }

  useEffect(() => {
    if (!error) return
    if (error.graphQLErrors.some(({ extensions }) => extensions?.status === 404)) {
      throw new NotFoundError(`Venue ${venueSlug} was not found`, error.message)
    }

    throw new Error(error.graphQLErrors.map(({ message }) => message).join(','))
  }, [error])

  if (!data || loading) {
    return <LoadingPage />
  }

  const reviews = data.venueContext.venue.reviews.edges.map(({ node, cursor }) => ({ ...node, cursor })) || []
  const trainers = data.venueContext.venue.trainers.edges.map(({ node, cursor }) => ({ ...node, cursor })) || []

  const meta = data.venueContext.meta
  const venue = {
    ...data.venueContext.venue,
    reviews,
    trainers,
  }

  return <VenueProfileComponent meta={meta} venue={venue} />
}

export default VenueProfile
