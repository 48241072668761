import * as React from 'react'
import { AnimatePresence } from '@eversports/klimt-utilities/framer-motion'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { useIntl } from '../../localization/react'
import getStaticPageMetadata from '../../helpers/get-static-page-metadata'
import MetaHelmet from '../../components/MetaHelmet'

import Notifications from './Notifications'

const NotificationsScreen = () => {
  const intl = useIntl()
  const { origin } = useSettingsContext()

  const meta = getStaticPageMetadata({
    title: intl.notificationsMetaTitle(),
    description: intl.notificationsMetaDescription(),
    url: origin,
  })

  return (
    <>
      <MetaHelmet meta={meta} noIndex />
      <Header position={{ xs: 'relative', lg: 'sticky' }} />
      <AnimatePresence mode="wait">
        <Notifications />
      </AnimatePresence>
      <Footer />
    </>
  )
}

export default NotificationsScreen
