import * as React from 'react'
import { DOMAttributes, type JSX } from 'react'
import { ReactSVG as InternalReactSVG, WrapperType } from 'react-svg'
import { Color, palette } from '@eversports/design-tokens/palette'

export interface SvgProps extends DOMAttributes<WrapperType> {
  src: string

  width: number
  height: number
  color?: Color
  loadingComponent?: JSX.Element
}

const Svg = ({ src, width, height, color, loadingComponent, ...rest }: SvgProps) => (
  <InternalReactSVG
    src={src}
    width={width}
    height={height}
    loading={() => loadingComponent ?? <div style={{ width, height }} />}
    beforeInjection={(svg) => {
      if (color) {
        svg.setAttribute('fill', palette[color])
      }
      svg.setAttribute('width', `${width}px`)
      svg.setAttribute('height', `${height}px`)
    }}
    {...rest}
  />
)

export default Svg
