import * as React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ReactConfetti from 'react-confetti'
import { Helmet } from 'react-helmet-async'
import Stack from '@eversports/klimt-primitives/Stack'
import FilledHorizontalLogo from '@eversports/design-tokens/assets/logo/filled-horizontal.svg'
import Box from '@eversports/klimt-primitives/Box'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import Avatar from '@eversports/klimt-primitives/Avatar'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'
import { motion } from '@eversports/klimt-utilities/framer-motion'
import EversportsLogoIcon from '@eversports/design-tokens/assets/logo/icon.svg'

import { Localized, useIntl } from '../../localization/react'
import getStaticPageMetadata from '../../helpers/get-static-page-metadata'
import MetaHelmet from '../../components/MetaHelmet'
import { useYearlyRecapRecapQuery } from '../../graphql'
import LoadingPage from '../../components/LoadingPage'
import useUrlParams from '../../hooks/use-url-params'
import { RECAP_YEAR } from '../../App.constants'
import BricolageGrotesqueFont from '../Home/assets/bricolage-grotesque-font-face'

import DefaultUserPicture from './assets/default-user-picture.webp'
import RecapSection from './RecapSection'
import RecapOverview from './RecapOverview'
import findMostActiveMonth from './helpers/find-most-active-month'
import { RecapSectionImage } from './RecapSectionImage'
import BackgroundPatternImage from './assets/background-pattern-image.webp'
import AnimatedTitle from './AnimatedTitle'
import NumberOfSportsVideo from './assets/number-of-sports-video.mp4'
import TopSportVideo from './assets/top-sport-video.mp4'
import ActiveDaysVideo from './assets/active-days-video.mp4'
import ActiveMinutesVideo from './assets/active-minutes-video.mp4'
import MostActiveMonthVideo from './assets/most-active-month-video.mp4'
import TopVenuesVideo from './assets/top-venues-video.mp4'
import StreakVideo from './assets/streak-video.mp4'
import TopTrainersVideo from './assets/top-teachers-video.mp4'
import TopFriendsVideo from './assets/top-friends-video.mp4'
import getRecapSectionSubtitles from './helpers/get-recap-section-subtitles'

const YearlyRecap = () => {
  const intl = useIntl()
  const { view } = useUrlParams()
  const { amplitude, logAmplitudeEventOnce } = useLogAmplitudeEventOnce()
  const { origin } = useSettingsContext()
  const { recapToken } = useParams<{ recapToken: string }>()
  const { data, loading } = useYearlyRecapRecapQuery({ variables: { token: recapToken } })
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 })

  useEffect(() => {
    if (!amplitude || !data) return
    logAmplitudeEventOnce({
      eventName: `Viewed ${RECAP_YEAR} Yearly Recap`,
      eventProperties: {
        userType: view ? 'visitor' : 'owner',
      },
    })
  }, [amplitude, data])

  useEffect(() => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }, [])

  if (!data || loading) {
    return <LoadingPage />
  }

  const recapUrl = `${origin}/user/${recapToken}/yearly-recap`

  const meta = getStaticPageMetadata({
    title: intl.yearlyRecapPageMetaTitle({ firstName: data.recap.user.firstName }),
    description: intl.yearlyRecapPageMetaDescription({ firstName: data.recap.user.firstName }),
    image: data.recap.user.profilePicture,
    url: recapUrl,
  })

  const topFriends = data.recap.friends.slice(0, 5)
  const topTrainers = data.recap.trainers.slice(0, 5)

  const friendsListItems = topFriends.map((friend) => {
    return {
      name: friend.friend.publicUser.firstName,
      count: friend.numberOfCommonActivities,
      imageSrc: friend.friend.publicUser.profilePicture
        ? friend.friend.publicUser.profilePicture.medium
        : DefaultUserPicture,
    }
  })

  const trainersListItems = topTrainers.map((trainer) => {
    return {
      name: trainer.trainer.venues.length
        ? `${trainer.trainer.nickname} - ${trainer.trainer.venues[0].name}`
        : trainer.trainer.nickname,
      count: trainer.activityCount,
      imageSrc: trainer.trainer.image ? trainer.trainer.image.medium : DefaultUserPicture,
    }
  })

  const venuesListItems = data.recap.venues.map((venue) => {
    return {
      name: venue.venue.name,
      count: venue.activityCount,
      imageSrc: venue.venue.logo ? venue.venue.logo?.medium : EversportsLogoIcon,
    }
  })

  const sportsListItems = data.recap.sports.map((sport) => {
    return {
      name: sport.sport.name,
      count: sport.activityCount,
    }
  })

  const mostActiveMonth = findMostActiveMonth(data.recap)
  const {
    favoriteVenueText,
    minutesActiveText,
    activeDaysText,
    mostActiveMonthText,
    sportsCountText,
    topSportText,
    topTrainerText,
    topFriendText,
    streakText,
  } = getRecapSectionSubtitles({ recap: data.recap, intl })

  return (
    <>
      <Helmet>
        <style>{BricolageGrotesqueFont}</style>
      </Helmet>
      <MetaHelmet meta={meta} noIndex />
      {windowSize.width > 0 && (
        <ReactConfetti
          width={windowSize.width}
          height={windowSize.height}
          style={{ pointerEvents: 'none' }}
          numberOfPieces={windowSize.width > 600 ? 1000 : 500}
          recycle={false}
          onConfettiComplete={(confetti) => {
            confetti?.reset()
          }}
        />
      )}
      <Box
        width="100%"
        sx={{
          backgroundImage: `url(${BackgroundPatternImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#F3F8F4',
          backgroundPosition: { xs: 'center', lg: 'initial' },
          height: '100dvh',
          overflowY: 'scroll',
          scrollSnapType: 'y mandatory',
        }}
      >
        <Stack alignItems="center" maxWidth="600px" margin="auto">
          <Stack
            gap={6}
            padding={4}
            width="100%"
            minHeight="100dvh"
            justifyContent="center"
            sx={{ scrollSnapAlign: 'start' }}
          >
            <Stack gap={6} textAlign="center" justifyContent="center" alignItems="center">
              <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}>
                <Box paddingBottom={6}>
                  <img width="185px" height="38px" src={FilledHorizontalLogo} />
                </Box>
              </motion.div>
              <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}>
                <Stack textAlign="center" gap={1}>
                  <Heading
                    is="h1"
                    sx={{
                      color: '#194148',
                      fontFamily: "'Bricolage Grotesque', 'Adjusted Bricolage Grotesque Fallback'",
                      fontWeight: 500,
                    }}
                  >
                    <Localized id="yearly-recap-title" params={{ firstName: data.recap.user.firstName }} />
                  </Heading>
                  <Text
                    sx={{
                      color: '#194148',
                      fontFamily: "'Bricolage Grotesque', 'Adjusted Bricolage Grotesque Fallback'",
                    }}
                  >
                    <Localized id="yearly-recap-subtitle" params={{ year: RECAP_YEAR }} />
                  </Text>
                </Stack>
              </motion.div>
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.5, type: 'spring', stiffness: 260, damping: 20 }}
              >
                <Avatar
                  src={data.recap.user.profilePicture?.medium ?? DefaultUserPicture}
                  sx={{
                    width: { xs: 120, md: 240 },
                    height: { xs: 120, md: 240 },
                    backgroundColor: 'gray',
                    boxShadow: boxShadows.hover,
                  }}
                />
              </motion.div>
            </Stack>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1, duration: 0.5 }}>
              <Stack gap={4} textAlign="center">
                <Heading
                  is="h2"
                  sx={{
                    color: '#194148',
                    fontSize: { xs: '28px', md: '57px' },
                    lineHeight: { xs: '32px', md: '64px' },
                    fontFamily: "'Bricolage Grotesque', 'Adjusted Bricolage Grotesque Fallback'",
                    fontWeight: 500,
                  }}
                >
                  <Localized id="yearly-recap-your-year" params={{ year: String(data.recap.year) }} />
                </Heading>
                <Text
                  variant="large"
                  sx={{
                    fontWeight: 'bold',
                    color: '#194148',
                    fontFamily: "'Bricolage Grotesque', 'Adjusted Bricolage Grotesque Fallback'",
                  }}
                >
                  <Localized id="yearly-recap-your-year-with-eversports" />
                </Text>
              </Stack>
            </motion.div>
          </Stack>
          <RecapSection
            title={data.recap.sports[0].sport.name}
            subtitle={topSportText}
            list={{ type: 'sport', items: sportsListItems }}
            backgroundVideo={TopSportVideo}
          />
          <RecapSection
            title={
              <Localized id="yearly-recap-sports-section-title" params={{ totalSportsCount: data.recap.sportsCount }} />
            }
            subtitle={sportsCountText}
            backgroundVideo={NumberOfSportsVideo}
          />
          <RecapSection
            title={
              <AnimatedTitle
                value={data.recap.activeDays}
                title={<Localized id="yearly-recap-active-days-section-title-text" />}
                variant="value-first"
              />
            }
            subtitle={activeDaysText}
            backgroundVideo={ActiveDaysVideo}
          />
          <RecapSection
            title={
              <AnimatedTitle
                value={data.recap.minutesActive}
                title={<Localized id="yearly-recap-active-minutes-section-title-text" />}
                variant="value-first"
              />
            }
            subtitle={minutesActiveText}
            backgroundVideo={ActiveMinutesVideo}
          />
          <RecapSection
            title={
              <Localized
                id="yearly-recap-most-active-month-section-title"
                params={{ monthName: <Localized id="month-of-the-year" params={{ month: mostActiveMonth?.month }} /> }}
              />
            }
            subtitle={mostActiveMonthText}
            backgroundVideo={MostActiveMonthVideo}
          />
          {data.recap.longestStreak.days >= 3 && (
            <RecapSection
              title={
                <AnimatedTitle
                  value={data.recap.longestStreak.days}
                  title={<Localized id="yearly-recap-streak-section-title-text" />}
                  variant="title-first"
                />
              }
              subtitle={streakText}
              backgroundVideo={StreakVideo}
            />
          )}
          <RecapSection
            title={venuesListItems[0].name}
            subtitle={favoriteVenueText}
            image={<RecapSectionImage type="venue" src={venuesListItems[0].imageSrc} variant="large" />}
            list={{ type: 'venue', items: venuesListItems }}
            backgroundVideo={TopVenuesVideo}
          />
          {topTrainers.length >= 3 && (
            <RecapSection
              title={data.recap.trainers[0].trainer.nickname}
              subtitle={topTrainerText}
              image={<RecapSectionImage type="person" src={trainersListItems[0].imageSrc} variant="large" />}
              list={{ type: 'person', items: trainersListItems }}
              backgroundVideo={TopTrainersVideo}
            />
          )}
          {topFriends.length >= 3 && (
            <RecapSection
              title={friendsListItems[0].name}
              subtitle={topFriendText}
              image={<RecapSectionImage type="person" src={friendsListItems[0].imageSrc} variant="large" />}
              list={{ type: 'person', items: friendsListItems }}
              backgroundVideo={TopFriendsVideo}
            />
          )}
          <RecapOverview recap={data.recap} />
        </Stack>
      </Box>
    </>
  )
}

export default YearlyRecap
