import { createTheme } from '@mui/material/styles'
import { Color, palette } from '@eversports/design-tokens/palette'
import { breakpoints } from '@eversports/design-tokens/breakpoints'
import { spacingUnit as spacing } from '@eversports/design-tokens/spacing'
import { borderRadius } from '@eversports/design-tokens/border-radius'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import { typography } from '@eversports/design-tokens/typography'

let baseTheme = createTheme({
  typography: {
    fontSize: typography.body.regular.fontSize,
    fontFamily: '"Figtree", "Figtree Fallback"',
    allVariants: {
      color: palette.darkgray,
    },
  },
  palette: {
    primary: {
      main: palette.primary,
    },
    secondary: {
      main: palette.secondary,
    },
    tertiary: palette.tertiary,
    brightgreen: palette.brightgreen,
    red: palette.red,
    midnight: palette.midnight,
    carbon: palette.carbon,
    darkgray: palette.darkgray,
    gray: palette.gray,
    mediumgray: palette.mediumgray,
    lightgray: palette.lightgray,
    limelite: palette.limelite,
    eggshell: palette.eggshell,
    whitesmoke: palette.whitesmoke,
    text: {
      primary: palette.darkgray,
    },
    white: palette.white,
    black: palette.black,
  },
  breakpoints: {
    values: breakpoints,
  },
  spacing,
  shape: {
    borderRadius: borderRadius.small,
  },
})

baseTheme = createTheme(baseTheme, {
  shadows: {
    ...baseTheme.shadows,
    0: boxShadows.default,
    1: boxShadows.hover,
  },
})

export type CustomPalette = Omit<
  {
    [key in Color]: string
  },
  'primary' | 'secondary' | 'text'
>

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Palette extends CustomPalette {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface PaletteOptions extends CustomPalette {}
}

export default baseTheme
