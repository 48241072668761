import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../localization/react'

import { Recap } from './YearlyRecap.types'
import TotalMinutesIcon from './assets/total-minutes-icon.svg'
import ActiveDaysIcon from './assets/active-days-icon.svg'
import NumberOfSportsIcon from './assets/number-of-sports-icon.svg'
import TopSportIcon from './assets/top-sport-icon.svg'
import TopTeacherIcon from './assets/top-teacher-icon.svg'
import TopFriendIcon from './assets/top-friend-icon.svg'
import StreakIcon from './assets/streak-icon.svg'
import RecapOverviewImage from './RecapOverviewImage'
import TopVenueIcon from './assets/top-venue-icon.svg'

export type RecapOverviewVariant = keyof ReturnType<typeof variants>

interface Props {
  variant: RecapOverviewVariant
  recap: Recap
}

const variants = (recap: Recap) => ({
  'top-venue': {
    title: recap.sports[0].sport.name,
    subtitle: <Localized id="yearly-recap-overview-top-sport-description" />,
    image: <RecapOverviewImage src={TopSportIcon} />,
  },
  'top-sport': {
    title: recap.venues[0].venue.name,
    subtitle: <Localized id="yearly-recap-overview-top-venue-description" />,
    image: <RecapOverviewImage src={TopVenueIcon} />,
  },
  'total-sports': {
    title: <Localized id="yearly-recap-overview-total-sports-title" params={{ totalSportsCount: recap.sportsCount }} />,
    subtitle: <Localized id="yearly-recap-overview-total-sports-description" />,
    image: <RecapOverviewImage src={NumberOfSportsIcon} />,
  },
  'total-days': {
    title: <Localized id="yearly-recap-overview-total-days-title" params={{ totalDaysCount: recap.activeDays }} />,
    subtitle: <Localized id="yearly-recap-overview-total-days-description" />,
    image: <RecapOverviewImage src={ActiveDaysIcon} />,
  },
  'total-minutes': {
    title: <Localized id="yearly-recap-overview-total-minutes-title" params={{ totalMinutes: recap.minutesActive }} />,
    subtitle: <Localized id="yearly-recap-overview-total-minutes-description" />,
    image: <RecapOverviewImage src={TotalMinutesIcon} />,
  },
  'top-teacher': {
    title: recap.trainers.length ? recap.trainers[0].trainer.nickname : '',
    subtitle: <Localized id="yearly-recap-overview-top-teacher-description" />,
    image: <RecapOverviewImage src={TopTeacherIcon} />,
  },
  'top-friend': {
    title: recap.friends.length ? recap.friends[0].friend.publicUser.firstName : '',
    subtitle: <Localized id="yearly-recap-overview-top-friend-description" />,
    image: <RecapOverviewImage src={TopFriendIcon} />,
  },
  streak: {
    title: recap.longestStreak.days,
    subtitle: <Localized id="yearly-recap-overview-streak-description" />,
    image: <RecapOverviewImage src={StreakIcon} />,
  },
})

const RecapOverviewItem = ({ variant, recap }: Props) => {
  const { title, subtitle, image } = variants(recap)[variant]
  return (
    <Stack direction="row" alignItems="center" gap={4.5} sx={{ textShadow: '0px 2px 4px rgba(0, 0, 0, 0.50)' }}>
      {image}
      <Stack sx={{ textAlign: 'start' }}>
        <Heading
          is="h5"
          sx={{
            fontSize: { xs: '18px', md: '26px' },
            lineHeight: { xs: '22px', md: '39px' },
            color: 'white',
            fontFamily: "'Bricolage Grotesque', 'Adjusted Bricolage Grotesque Fallback'",
            fontWeight: 500,
          }}
        >
          {title}
        </Heading>
        <Text
          sx={{
            fontSize: { xs: 14, md: 16 },
            color: 'white',
            fontFamily: "'Bricolage Grotesque', 'Adjusted Bricolage Grotesque Fallback'",
          }}
        >
          {subtitle}
        </Text>
      </Stack>
    </Stack>
  )
}

export default RecapOverviewItem
