import { styled } from '@mui/material'

import { type CustomPalette } from './base-theme'

export default styled

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Palette extends CustomPalette {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface PaletteOptions extends CustomPalette {}
}
