import * as React from 'react'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Box, { BoxProps } from '@eversports/klimt-primitives/Box'

type Props = Pick<BoxProps, 'onClick'>

const FavoriteAndShareButtonsBox = ({ onClick, children }: React.PropsWithChildren<Props>) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        paddingY: 2,
        paddingX: { xs: 2, sm: 4 },
        width: { xs: '100%', sm: 'fit-content' },
        height: 'fit-content',
        border: '1px solid',
        borderColor: 'mediumgray',
        borderRadius: 1,
        cursor: 'pointer',
        backgroundColor: 'white',
        '&:hover': {
          boxShadow: boxShadows.default,
        },
      }}
    >
      {children}
    </Box>
  )
}

export default FavoriteAndShareButtonsBox
