import * as React from 'react'
import useInView from '@eversports/klimt-utilities/use-intersection-observer'

import { useAnimatedCounter } from './hooks/use-animated-counter'

interface Props {
  value: number
  title: React.ReactNode
  variant: 'title-first' | 'value-first'
}

export default function AnimatedTitle({ value, title, variant }: Props) {
  const { ref, inView } = useInView({ threshold: 0.1 })
  const animatedActiveMinutes = useAnimatedCounter(value, inView)

  if (variant === 'value-first') {
    return (
      <div className="flex flex-col justify-center gap-1 sm:flex-row">
        <span className="pr-1" ref={ref}>
          {animatedActiveMinutes}
        </span>
        {title}
      </div>
    )
  } else {
    return (
      <div className="flex flex-col justify-center gap-1 sm:flex-row">
        {title}
        <span className="pl-1" ref={ref}>
          {animatedActiveMinutes}
        </span>
      </div>
    )
  }
}
