import * as React from 'react'
import { domToBlob } from 'modern-screenshot'
import mergeRefs from 'react-merge-refs'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import { motion } from '@eversports/klimt-utilities/framer-motion'
import useInView from '@eversports/klimt-utilities/use-intersection-observer'
import ShareIcon from '@eversports/design-tokens/assets/icons/share.svg'
import Icon from '@eversports/klimt-primitives/Icon'
import FilledHorizontalLogo from '@eversports/design-tokens/assets/logo/filled-inverted-horizontal.svg'
import ClientLogger from '@eversports/klimt-utilities/client-logger'
import { useState } from 'react'
import NoSsr from '@eversports/klimt-design-components/NoSsr'

import { Localized } from '../../localization/react'

import RecapList, { ListItem, ListType } from './RecapList'

interface Props {
  title: React.ReactNode
  subtitle: React.ReactNode
  backgroundVideo: string
  image?: React.ReactNode
  list?: { type: ListType; items: Array<ListItem> }
}

const RecapSection = ({ title, subtitle, image, list, backgroundVideo }: Props) => {
  const [isGeneratingImage, setIsGeneratingImage] = useState(false)
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  const containerRef = React.useRef<HTMLDivElement>(null)

  const handleShare = async (_e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!containerRef.current) return

    setIsGeneratingImage(true)

    try {
      const blob = await domToBlob(containerRef.current, {
        quality: 1,
        scale: 2,
        fetch: {
          requestInit: {
            cache: 'no-cache',
            mode: 'cors',
          },
          bypassingCache: true,
        },
        filter: (node) => {
          return !(node instanceof Element && node.getAttribute('data-exclude'))
        },
      })

      if (!blob) {
        throw new Error('Failed to generate section screenshot blob.')
      }

      const data = {
        title: 'Eversports Recap',
        files: [new File([blob], 'eversports-recap.png', { type: 'image/png' })],
      }

      if (navigator.canShare(data) && navigator.share) {
        await navigator.share(data)
      } else {
        // Fallback: Trigger download instead (for browsers without Web Share API support)
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'eversports-recap.png'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(url)
      }
    } catch (error) {
      ClientLogger.warning('Failed to generate overview recap image for sharing:', error as Error)
    } finally {
      setIsGeneratingImage(false)
    }
  }

  const containerVariants = {
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        when: 'beforeChildren',
        staggerChildren: 0.2,
      },
    },
  }

  const shareButtonVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  }

  return (
    <NoSsr>
      <motion.div
        ref={mergeRefs([ref, containerRef])}
        variants={containerVariants}
        initial="hidden"
        className="relative min-h-dvh w-full overflow-hidden"
        animate={inView ? 'visible' : 'hidden'}
        style={{ scrollSnapAlign: 'start', scrollSnapStop: 'always' }}
      >
        <video
          autoPlay
          muted
          loop
          playsInline
          className="absolute left-0 top-0 z-0 size-full object-cover"
          crossOrigin="anonymous"
        >
          <source src={backgroundVideo} type="video/mp4" />
        </video>
        <div className="relative z-10 min-h-dvh bg-black bg-opacity-40">
          <Stack
            gap={{ xs: 3, md: 6 }}
            textAlign="center"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100dvh"
          >
            <img width="140px" height="28px" src={FilledHorizontalLogo} loading="lazy" className="absolute top-7" />
            {image && (
              <motion.div
                initial={{ scale: 0 }}
                transition={{ delay: 0.2, type: 'spring', stiffness: 260, damping: 20 }}
                animate={inView ? { scale: 1, opacity: 1 } : { scale: 0, opacity: 0 }}
              >
                {image}
              </motion.div>
            )}
            <Stack
              gap={{ xs: 2, md: 4 }}
              paddingX={{ xs: 2, md: 4 }}
              sx={{ textShadow: '0px 2px 4px rgba(0, 0, 0, 0.50)' }}
            >
              <Heading
                is="h3"
                sx={{
                  color: 'white',
                  fontSize: { xs: '24px', md: '36px' },
                  lineHeight: { xs: '28px', md: '44px' },
                  fontFamily: "'Bricolage Grotesque', 'Adjusted Bricolage Grotesque Fallback'",
                  fontWeight: 500,
                }}
              >
                {title}
              </Heading>
              <Text
                variant="large"
                sx={{
                  color: 'white',
                  fontFamily: "'Bricolage Grotesque', 'Adjusted Bricolage Grotesque Fallback'",
                  fontSize: { xs: 14, md: 16 },
                }}
              >
                {subtitle}
              </Text>
            </Stack>
            {list && <RecapList type={list.type} items={list.items} />}
            <motion.button
              variants={shareButtonVariants}
              onClick={(e) => void handleShare(e)}
              className={`absolute bottom-2 flex items-center gap-2 rounded border bg-white p-1 shadow-lg sm:bottom-4 sm:px-2 ${
                isGeneratingImage && 'disabled:pointer-events-none disabled:opacity-50'
              }`}
              disabled={isGeneratingImage}
              data-exclude="true"
            >
              <Icon src={ShareIcon} size="default" color="primary" />
              <Text
                sx={{
                  fontSize: { xs: 14, md: 16 },
                  color: 'primary.main',
                  fontFamily: "'Bricolage Grotesque', 'Adjusted Bricolage Grotesque Fallback'",
                }}
              >
                <Localized id="yearly-recap-share-section-button" />
              </Text>
              {isGeneratingImage && (
                <div className="absolute inset-0 flex items-center justify-center bg-[#f2f2f2]">
                  <div className="size-2 animate-spin rounded-full border-2 border-solid border-border-brand-secondary border-t-transparent" />
                </div>
              )}
            </motion.button>
          </Stack>
        </div>
      </motion.div>
    </NoSsr>
  )
}

export default RecapSection
