import TextField from '@eversports/klimt-primitives/TextField'
import * as React from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { useOrganizerNotesSetNoteOnMatchMutation } from '../../../../graphql'
import { useIntl } from '../../../../localization/react'

import { TIME_UNTIL_SAVE } from './OrganizerNotes.constants'

interface Props {
  handleSaveNote: () => void
  initialNote?: string | null
  isDisabled?: boolean
}

const OrganizerNotesContent = ({ handleSaveNote, initialNote, isDisabled }: Props) => {
  const intl = useIntl()
  const { id } = useParams<{ id: string }>()
  const [note, setNote] = useState(initialNote || '')
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined)

  const [setOrganizerNotes] = useOrganizerNotesSetNoteOnMatchMutation({ onCompleted: handleSaveNote })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value)
  }

  const handleKeyUp = () => {
    if (timer) {
      clearTimeout(timer)
    }

    const localTimer = setTimeout(() => {
      void setOrganizerNotes({ variables: { matchId: id, note } })
    }, TIME_UNTIL_SAVE)

    setTimer(localTimer)
  }

  return (
    <TextField
      id="organizer-notes"
      rows={3}
      value={note}
      onChange={handleChange}
      onKeyUp={handleKeyUp}
      placeholder={intl.organizerNotePlaceholder()}
      multiline
      fullWidth
      disabled={isDisabled}
    />
  )
}

export default OrganizerNotesContent
