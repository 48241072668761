import * as React from 'react'
import { useState } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Button from '@eversports/klimt-primitives/Button'
import Icon from '@eversports/klimt-primitives/Icon'
import ArrowRight from '@eversports/design-tokens/assets/icons/arrow-forward.svg'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'
import NoSsr from '@eversports/klimt-design-components/NoSsr'
import { UserFeaturePreviews } from '@eversports/react-app-base/user-feature-previews'

import { Localized } from '../../../../../../../localization/react'
import { SuggestedType, useSuggestedFriendsMeQuery } from '../../../../../../../graphql'
import useEnabledFeaturePreviews from '../../../../../../../hooks/useEnabledFeaturePreviews'

import SuggestedFriend from './SuggestedFriend'
import AllSuggestedFriends from './AllSuggestedFriends'
import { INITIAL_NUMBER_OF_SUGGESTED_FRIENDS_TO_SHOW } from './SuggestedFriends.constants'
import SuggestedFriendsPlaceholder from './SuggestedFriendsPlaceholder'

const SuggestedFriends = () => {
  const { amplitude, logAmplitudeEventOnce } = useLogAmplitudeEventOnce()
  const [showAll, setShowAll] = useState(false)
  const { data, loading } = useSuggestedFriendsMeQuery({
    variables: {
      first: INITIAL_NUMBER_OF_SUGGESTED_FRIENDS_TO_SHOW,
      suggestedTypes: [SuggestedType.COMMON_FRIENDS, SuggestedType.COMMON_ACTIVITIES],
    },
  })

  if (loading) {
    return <SuggestedFriendsPlaceholder />
  }

  if (!data || !data.me || data.me?.suggestedFriends.edges.length === 0) {
    return null
  }

  const suggestedFriends = data.me?.suggestedFriends.edges.map((edge) => edge.node)
  const hasMoreSuggestions = data.me?.suggestedFriends.pageInfo.hasNextPage

  const toggleShowAll = () => {
    setShowAll(!showAll)
    if (!showAll && amplitude) {
      logAmplitudeEventOnce({ eventName: 'Viewed Suggested Friends Page', eventProperties: {} })
    }
  }

  return (
    <Stack gap={2} marginBottom={4}>
      <Stack direction="row" sx={{ overflowX: 'scroll' }} paddingBottom={2} gap={2}>
        {suggestedFriends.map((friend) => (
          <SuggestedFriend friend={friend} key={friend.id} />
        ))}
      </Stack>
      {hasMoreSuggestions && (
        <>
          <Button
            variant="tertiary"
            sx={{
              textTransform: 'none',
              width: 'fit-content',
              marginLeft: 'auto',
              paddingY: 1,
              paddingX: 2,
            }}
            onClick={toggleShowAll}
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <Text sx={{ color: 'midnight', fontWeight: 'bold' }}>
                <Localized id="suggested-friends-show-all" />
              </Text>
              <Icon src={ArrowRight} color="midnight" size="small" />
            </Stack>
          </Button>
          <AllSuggestedFriends isOpen={showAll} toggleDialog={toggleShowAll} />
        </>
      )}
    </Stack>
  )
}

const SuggestedFriendsWrapper = () => {
  const enabledFeaturePreviews = useEnabledFeaturePreviews()
  const isSuggestedFriendsEnabled = enabledFeaturePreviews.includes(UserFeaturePreviews.SUGGESTED_FRIENDS)

  if (!isSuggestedFriendsEnabled) {
    return null
  }

  return (
    <NoSsr fallback={<SuggestedFriendsPlaceholder />}>
      <SuggestedFriends />
    </NoSsr>
  )
}

export default SuggestedFriendsWrapper
