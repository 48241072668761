import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import * as React from 'react'

import { MatchesFiltersState } from '../Matches.types'
import { Action } from '../helpers/matches-filters-reducer'
import { Localized } from '../../../../../localization/react'

interface Props {
  appliedFilters: MatchesFiltersState
  dispatch: React.Dispatch<Action>
}

const ResetFilters = ({ appliedFilters, dispatch }: Props) => {
  const handleClick = () => {
    dispatch({ type: 'RESET_MATCHES_FILTERS' })
  }

  const isVisible = Boolean(appliedFilters.sports?.length || appliedFilters.date)

  return isVisible ? (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      onClick={handleClick}
      display={{ xs: 'none', md: 'flex' }}
      gap={1}
      sx={{
        border: '1px solid',
        backgroundColor: 'white',
        borderColor: 'mediumgray',
        boxSizing: 'border-box',
        borderRadius: 1,
        py: 2,
        px: 5,
        cursor: 'pointer',
        '&:hover': {
          borderColor: 'primary.main',
        },
      }}
    >
      <Text variant="small" sx={{ color: 'primary.main' }}>
        <Localized id="filters-reset-all" />
      </Text>
    </Stack>
  ) : null
}

export default ResetFilters
