import * as React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import { default as MuiCheckbox, CheckboxProps } from '@mui/material/Checkbox'
import { TextVariants } from '@eversports/design-tokens/typography'

import Text from '../Text'

export interface Props extends CheckboxProps {
  label: React.ReactNode
  labelVariant?: keyof TextVariants
}

const isSimpleTextNode = (node: React.ReactNode): node is string | number => {
  return typeof node === 'string' || typeof node === 'number'
}

const Checkbox = ({ inputProps, label, labelVariant = 'regular', ...props }: React.PropsWithChildren<Props>) => {
  const renderLabel = () => {
    if (isSimpleTextNode(label)) {
      return <Text variant={labelVariant}>{label}</Text>
    }
    return label
  }

  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          {...props}
          inputProps={{
            ...inputProps,
            'aria-label': isSimpleTextNode(label) ? (label as string) : undefined,
          }}
        />
      }
      label={renderLabel()}
      sx={{
        margin: 0,
      }}
    />
  )
}

export default Checkbox
