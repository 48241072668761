import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import styled from '@eversports/klimt-design-components/styled'

export const FlickerLoading = styled(Box)`
  @keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  animation: flickerAnimation ease 1.5s infinite;
`

const FilterSkeleton = () => {
  return (
    <FlickerLoading
      gap={2}
      sx={{
        height: '38px',
        flex: '1 1 auto',
        border: '1px solid',
        borderColor: 'mediumgray',
        boxSizing: 'border-box',
        borderRadius: 1,
        py: 2,
        px: { xs: 3, sm: 5 },
        maxWidth: { lg: '150px' },
      }}
    />
  )
}

export default FilterSkeleton
