import { hydrateRoot } from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'
import * as React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { DEFAULT_LANGUAGE, getLanguageFromLanguageWithLocale, Language } from '@eversports/language'
import ThemeProvider from '@eversports/klimt-design-components/ThemeProvider'
import Baseline from '@eversports/klimt-design-components/Baseline'
import EmotionCacheProvider from '@eversports/klimt-design-components/EmotionCacheProvider'
import createEmotionCache from '@eversports/klimt-design-components/create-emotion-cache'
import parser from 'ua-parser-js'
import theme, { createTheme } from '@eversports/klimt-design-components/theme'
import customMediaQueryMatcher from '@eversports/klimt-design-components/custom-media-query-matcher'
import AmplitudeProvider, { TrackingStrategy } from '@eversports/amplitude-react/AmplitudeProvider'

import createApolloClient, { CreateApolloOptions } from './create-apollo-client'
import { SettingsContext } from './SettingsContext'
import { getWindow } from './window'

import { HttpStatusCollector, HttpStatusObject } from '@eversports/react-app-base/http-status/HttpStatusCollector'

// Although this is a side effect that gets implicitly called by importing the function below this should
// be an acceptable trade-off
if (module.hot) {
  module.hot.accept()
}

interface Options {
  App: React.FC<React.PropsWithChildren<unknown>>
  basename?: string
  getLocalizationProvider: (language: Language) => Promise<React.FC<React.PropsWithChildren<unknown>>>
  apolloOptions: CreateApolloOptions
  amplitudeOptions?: {
    trackingStrategy?: TrackingStrategy
  }
}

const cache = createEmotionCache()

async function modernHydrateClient({
  App,
  basename,
  getLocalizationProvider,
  apolloOptions,
  amplitudeOptions,
}: Options) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const apolloClient = createApolloClient({ ...apolloOptions, state: getWindow().__APOLLO_STATE__ })
  const language = getLanguageFromLanguageWithLocale(navigator.language) || DEFAULT_LANGUAGE
  const LocalizationProvider = await getLocalizationProvider(language)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Custom window type
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
  const settings = { host: window.location.host, protocol: window.location.protocol, language }

  const deviceType = parser(navigator.userAgent).device.type || 'desktop'
  const ssrMatchMedia = customMediaQueryMatcher(deviceType)

  const extendedTheme = createTheme(theme, {
    components: {
      MuiUseMediaQuery: {
        defaultProps: {
          ssrMatchMedia,
        },
      },
    },
  })

  const container = document.getElementById('root')

  if (!container) {
    throw new Error('No react root container found')
  }

  const amplitudeProjectApiKey = getWindow().env.AMPLITUDE_PROJECT_API_KEY

  const httpStatusObject: HttpStatusObject = {
    status: getWindow().statusCode,
  }

  const ClientApp = (
    <HttpStatusCollector statusObject={httpStatusObject}>
      <LocalizationProvider>
        <BrowserRouter basename={basename}>
          <HelmetProvider>
            <ApolloProvider client={apolloClient}>
              <AmplitudeProvider
                amplitudeProjectApiKey={amplitudeProjectApiKey}
                trackingStrategy={amplitudeOptions?.trackingStrategy}
              >
                <SettingsContext settings={settings}>
                  <EmotionCacheProvider value={cache}>
                    <ThemeProvider theme={extendedTheme}>
                      <Baseline />
                      <React.StrictMode>
                        <App />
                      </React.StrictMode>
                    </ThemeProvider>
                  </EmotionCacheProvider>
                </SettingsContext>
              </AmplitudeProvider>
            </ApolloProvider>
          </HelmetProvider>
        </BrowserRouter>
      </LocalizationProvider>
    </HttpStatusCollector>
  )

  hydrateRoot(container, ClientApp)
}

export default modernHydrateClient
