import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Icon from '@eversports/klimt-primitives/Icon'
import Text from '@eversports/klimt-primitives/Text'
import Dialog from '@eversports/klimt-primitives/Dialog'
import * as React from 'react'
import ChevronLeftIcon from '@eversports/design-tokens/assets/icons/chevron-left.svg'

import { Localized } from '../../../localization/react'

import Comment from './Comment'
import { Comment as CommentType } from './CommentsSection.types'
import NewCommentInput from './NewCommentInput'
import CommentsArea from './CommentsArea'
import ViewMoreComments from './ViewMoreComments'

interface Props {
  isOpen: boolean
  isLoadingMoreComments: boolean
  hasMoreResults?: boolean
  onClose: () => void
  onSubmit: ({ comment }: { comment: string }) => void
  onShowMoreComments: () => void
  comments: Array<CommentType>
  isEmpty: boolean
}

const FullscreenCommentsSection = ({
  isOpen,
  isLoadingMoreComments,
  hasMoreResults,
  onClose,
  onSubmit,
  onShowMoreComments,
  comments,
  isEmpty,
}: Props) => {
  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen>
      <Stack height="100%">
        <Stack
          direction="row"
          alignItems="center"
          p={4}
          sx={{ position: 'sticky', top: 0, backgroundColor: 'white', boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.16)' }}
        >
          <Box sx={{ flex: 1 }}>
            <Box
              sx={{
                padding: 1,
                border: '1px solid',
                borderColor: 'lightgray',
                boxShadow: boxShadows.default,
                width: 'fit-content',
                borderRadius: 1,
              }}
              onClick={onClose}
            >
              <Icon src={ChevronLeftIcon} size="small" color="midnight" />
            </Box>
          </Box>
          <Text sx={{ color: 'midnight', fontWeight: 'bold', flex: 1, textAlign: 'center' }}>
            <Localized id="comments-section-title" />
          </Text>
          <Text
            variant="xsmall"
            sx={{
              color: 'primary.main',
              textDecoration: 'underline',
              cursor: 'pointer',
              textAlign: 'right',
              flex: 1,
            }}
          >
            {/* TODO: Uncomment when we have notifications */}
            {/*  <Localized id="comments-section-notifications-title" />*/}
          </Text>
        </Stack>
        <CommentsArea variant="fullscreen" isEmpty={isEmpty}>
          {comments.map((comment) => (
            <Comment key={comment.id} comment={comment} />
          ))}
          {hasMoreResults && (
            <ViewMoreComments handleClick={onShowMoreComments} isLoadingMoreComments={isLoadingMoreComments} />
          )}
        </CommentsArea>
        <Box
          sx={{
            backgroundColor: 'white',
            position: 'sticky',
            bottom: 0,
            boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.16)',
          }}
        >
          <NewCommentInput onSubmit={onSubmit} />
        </Box>
      </Stack>
    </Dialog>
  )
}

export default FullscreenCommentsSection
