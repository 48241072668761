import * as React from 'react'
import { AnimatePresence } from '@eversports/klimt-utilities/framer-motion'

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import DataSubProcessors from './DataSubProcessors'

const DataSubProcessorsScreen = () => {
  return (
    <>
      <Header />
      <AnimatePresence mode="wait">
        <DataSubProcessors />
      </AnimatePresence>
      <Footer />
    </>
  )
}

export default DataSubProcessorsScreen
