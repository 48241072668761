import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Skeleton from '@eversports/klimt-primitives/Skeleton'

import LayoutContainer from './components/LayoutContainer'
import BannerSection from './BannerSection'

const LoadingMatch = () => {
  return (
    <Stack flexDirection="column" gap={2} sx={{ flex: 1, backgroundColor: 'limelite', minHeight: '100vh' }}>
      <BannerSection />
      <LayoutContainer>
        <Skeleton variant="rectangular" width="100%" height="200px" />
        <Skeleton variant="rectangular" width="100%" height="150px" />
        <Skeleton variant="rectangular" width="100%" height="316px" />
      </LayoutContainer>
    </Stack>
  )
}

export default LoadingMatch
