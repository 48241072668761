import * as React from 'react'
import NoSsr from '@eversports/klimt-design-components/NoSsr'

import Match from './Match'
import Header from './Header'
import Footer from './Footer'
import LoadingMatch from './LoadingMatch'

const MatchPage = () => {
  return (
    <>
      <Header />
      <NoSsr fallback={<LoadingMatch />}>
        <Match />
        <Footer />
      </NoSsr>
    </>
  )
}

export default MatchPage
