import * as React from 'react'
import { useState, type JSX } from 'react'

import DropdownContainer from './DropdownContainer'
import { SearchComponentProps, InventorySearchResult, LocationSearchResult } from './Search.types'
import MobileDrawerSearch from './MobileDrawerSearch'
import SearchInput from './SearchInput'
import useKeyboardToNavigateResults from './hooks/useKeyboardToNavigateResults'
import SearchResults from './SearchResults'
import NoResults from './NoResults'

interface Props<T> extends SearchComponentProps<T> {
  placeholder: string
  iconAdornment: JSX.Element
  inputLabel: string
  hasInputLabels?: boolean
  hasSearchButton?: boolean
  isDefaultOpen?: boolean
}

const SearchComponent = ({
  results,
  suggestedResults = [],
  searchValue,
  setSearchValue,
  handleResultClick,
  handleResultEnter,
  isLoading,
  inputLabel,
  searchPosition,
  iconAdornment,
  placeholder,
  hasInputLabels,
  hasSearchButton,
  isDefaultOpen = false,
}: Props<LocationSearchResult | InventorySearchResult>) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen)

  const openResults = () => {
    setIsOpen(true)
  }

  const closeResults = () => {
    setIsOpen(false)
  }

  const closeResultsAndClear = () => {
    setIsOpen(false)
    setSearchValue('')
  }

  const hasResults = Boolean(results.length)

  const { selectedIndex } = useKeyboardToNavigateResults({
    results: hasResults ? results : suggestedResults,
    isResultsDropdownOpen: isOpen,
    onArrow: openResults,
    onClose: closeResults,
    onEnter: handleResultEnter,
  })

  return (
    <>
      <SearchInput
        inputLabel={hasInputLabels ? inputLabel : undefined}
        searchPosition={searchPosition}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        placeholder={placeholder}
        isLoading={isLoading}
        iconAdornment={iconAdornment}
        handleFocus={openResults}
        handleClick={openResults}
        isOpen={isOpen}
        hasSearchButton={hasSearchButton}
      />
      <DropdownContainer
        searchPosition={searchPosition}
        inputLabel={inputLabel}
        isOpen={isOpen}
        closeResults={closeResults}
      >
        {hasResults ? (
          <SearchResults
            results={results}
            handleResultClick={handleResultClick}
            handleClose={closeResults}
            selectedIndex={selectedIndex}
          />
        ) : (
          <NoResults
            searchValue={searchValue}
            suggestedResults={suggestedResults}
            handleResultClick={handleResultClick}
            handleClose={closeResults}
            selectedIndex={selectedIndex}
          />
        )}
      </DropdownContainer>
      <MobileDrawerSearch
        isLoading={isLoading}
        isOpen={isOpen}
        closeResults={closeResultsAndClear}
        inputLabel={inputLabel}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      >
        {hasResults ? (
          <SearchResults
            results={results}
            handleResultClick={handleResultClick}
            handleClose={closeResults}
            selectedIndex={selectedIndex}
          />
        ) : (
          <NoResults
            searchValue={searchValue}
            suggestedResults={suggestedResults}
            handleResultClick={handleResultClick}
            handleClose={closeResults}
            selectedIndex={selectedIndex}
          />
        )}
      </MobileDrawerSearch>
    </>
  )
}

export default SearchComponent
