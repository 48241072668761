import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import SeoLink from '@eversports/klimt-primitives/SeoLink'
import Text from '@eversports/klimt-primitives/Text'
import type { JSX } from 'react'

import { City } from '../Discover.types'
import { useIntl } from '../../../localization/react'
import { DiscoverPrefix, Sport } from '../../../App.types'

interface Props {
  city: City
  sports: Array<Sport>
  heading: JSX.Element
}

const SportTagsSection = ({ city, sports, heading }: Props) => {
  const intl = useIntl()
  return (
    <Stack spacing={4}>
      <Heading is="h3" variant="small">
        {heading}
      </Heading>
      <Stack direction="row" flexWrap="wrap" rowGap={2} columnGap={4}>
        {sports.map((sport) => (
          <Box
            sx={{
              padding: '6px 12px',
              background: '#FFFFFF',
              borderRadius: '8px',
              filter: 'drop-shadow(0px 1px 2px #DDDDDD)',
              '&:hover': { backgroundColor: '#F2F2F2' },
            }}
            key={sport.id}
          >
            <SeoLink
              type="crawler"
              encodedTo={btoa(`${DiscoverPrefix.APP_SPORT_CITY}/${sport.slug}/${city.slug}`)}
              crawlerLink={`${DiscoverPrefix.SEO_SPORT_CITY}/${sport.slug}/${city.slug}`}
              blank
              ariaLabel={intl.sportTagSportInCity({ sportName: sport.name, cityName: city.name })}
            >
              <Text variant="small">{sport.name}</Text>
            </SeoLink>
          </Box>
        ))}
      </Stack>
    </Stack>
  )
}
export default SportTagsSection
