import * as React from 'react'
import { useEffect } from 'react'
import { AnimatePresence } from '@eversports/klimt-utilities/framer-motion'
import { useParams } from 'react-router-dom'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Search from '../../components/Search'

import VenueProfile from './VenueProfile'

const VenueProfilePage = () => {
  const { venueSlug } = useParams<{ venueSlug: string }>()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [venueSlug])

  return (
    <>
      <Header position={{ xs: 'relative', lg: 'sticky' }}>
        <Search />
      </Header>
      <AnimatePresence mode="wait">
        <VenueProfile />
      </AnimatePresence>
      <Footer />
    </>
  )
}

export default VenueProfilePage
