import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'

import { Localized } from '../../../../localization/react'

interface Props {
  index: 1 | 2
}

const CreateYourMatchCardAdvantage = ({ index }: Props) => {
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Box paddingX={1} sx={{ backgroundColor: 'rgba(33, 166, 150, 0.12)', borderRadius: 1 }}>
        <Text variant="xsmall" sx={{ color: 'primary.main' }}>
          {index}
        </Text>
      </Box>
      <Text variant="xsmall" sx={{ fontWeight: 'bold' }}>
        <Localized id={`create-your-match-card-step-${index}` as any} />
      </Text>
    </Stack>
  )
}

export default CreateYourMatchCardAdvantage
