import { useState, useEffect } from 'react'
import { animate } from '@eversports/klimt-utilities/framer-motion'

const calculateDuration = (end: number) => {
  const baseDuration = 1 // Minimum duration in seconds for very small numbers
  const scalingFactor = 0.2 // Pacing factor
  const maxDuration = 4 // Cap duration for very large numbers

  return Math.min(baseDuration + Math.log10(Math.max(end, 1)) * scalingFactor, maxDuration)
}

export function useAnimatedCounter(end: number, isInView: boolean) {
  const [isAnimated, setIsAnimated] = useState(false)
  const [count, setCount] = useState(0)
  const duration = calculateDuration(end)

  useEffect(() => {
    if (!isInView || isAnimated) return

    const controls = animate(0, end, {
      duration,
      type: 'spring',
      stiffness: 100,
      damping: 20,
      onComplete: () => {
        setIsAnimated(true)
      },
      onUpdate: (value) => setCount(Math.round(value)),
    })

    return () => controls.stop()
  }, [end, duration, isInView])

  return count
}
