import * as React from 'react'
import Divider from '@eversports/klimt-primitives/Divider'
import Box from '@eversports/klimt-primitives/Box'
import Icon from '@eversports/klimt-primitives/Icon'
import CheckmarkIcon from '@eversports/design-tokens/assets/icons/checkmark.svg'
import Processing from '@eversports/klimt-primitives/Processing'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import NewsletterIcon from '../assets/newsletter.svg'
import {
  useSubscribeToNewsletterMeQuery,
  useSubscribeToNewsletterSubscribeToNewsletterMutation,
} from '../../../../graphql'
import { Localized } from '../../../../localization/react'

interface Props {
  venueId: string
  venueName: string
}

export function SubscribeToNewsletter({ venueId, venueName }: Props) {
  const { amplitude } = useAmplitude()
  const { data, loading } = useSubscribeToNewsletterMeQuery({ variables: { venueId } })
  const [subscribeToNewsletter, { loading: isSubscribing }] = useSubscribeToNewsletterSubscribeToNewsletterMutation()

  if (!data || loading || !data.me?.customers.nodes.length) return null

  const customer = data.me.customers.nodes[0]

  const handleClick = () => {
    void subscribeToNewsletter({ variables: { customerId: customer.id } })
    if (amplitude) {
      amplitude.logEvent('Subscribed to Venue Newsletter', {
        venue: {
          name: venueName,
        },
        where: 'venue profile',
      })
    }
  }

  return (
    <Stack gap={4}>
      <Stack gap={2}>
        <Text variant="large" sx={{ color: 'midnight', fontWeight: 600 }}>
          <Localized id="subscribe-to-newsletter-title" />
        </Text>
        <Text variant="regular" sx={{ color: 'darkgray' }}>
          <Localized id="subscribe-to-newsletter-description" params={{ venueName }} />
        </Text>
      </Stack>
      {customer.isSubscribedToNewsletter ? (
        <Stack gap={2} justifyContent="center" alignItems="center" width="100%">
          <Stack gap={2} flexDirection="row" paddingX={3} paddingY={2} justifyContent="center" alignItems="center">
            <Icon src={CheckmarkIcon} color="primary" size="small" />
            <Text variant="regular" sx={{ color: 'darkgray', fontWeight: 500 }}>
              <Localized id="subscribe-to-newsletter-subscribed" />
            </Text>
          </Stack>
          <Text variant="badge" sx={{ color: 'gray' }}>
            <Localized id="subscribe-to-newsletter-unsubscribe-info" />
          </Text>
        </Stack>
      ) : (
        <Processing isLoading={isSubscribing}>
          <Box
            component="button"
            sx={{
              gap: 1,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 1px 1px 0px rgba(20, 20, 20, 0.20)',
              border: '1px solid',
              borderColor: 'lightgray',
              backgroundColor: 'white',
              borderRadius: 1,
              paddingX: 4,
              paddingY: 2,
              '&:hover': {
                boxShadow: boxShadows.hover,
              },
            }}
            onClick={handleClick}
          >
            <Icon src={NewsletterIcon} color="darkgray" size="small" />
            <Text variant="regular" sx={{ color: 'darkgray', fontWeight: 500 }}>
              <Localized id="subscribe-to-newsletter-subscribe" />
            </Text>
          </Box>
        </Processing>
      )}
      <Divider sx={{ borderColor: 'lightgray' }} />
    </Stack>
  )
}
