import * as React from 'react'
import { ChangeEvent, useEffect, useState } from 'react'
import Box from '@eversports/klimt-primitives/Box'
import TextField from '@eversports/klimt-primitives/TextField'
import SearchIcon from '@eversports/design-tokens/assets/icons/search.svg'
import ErrorIcon from '@eversports/design-tokens/assets/icons/error.svg'
import Heading from '@eversports/klimt-primitives/Heading'
import Stack from '@eversports/klimt-primitives/Stack'
import useDebounce from '@eversports/klimt-utilities/use-debounce'
import Icon from '@eversports/klimt-primitives/Icon'
import Text from '@eversports/klimt-primitives/Text'

import { Localized, useIntl } from '../../../../../../localization/react'
import { useSearchContentUserSearchLazyQuery } from '../../../../../../graphql'
import LoadingDots from '../../../../../../components/LoadingDots'
import PublicUser from '../../../../components/PublicUser'
import { From } from '../../../../../../App.types'

const SearchContent = () => {
  const intl = useIntl()
  const [searchTerm, setSearchTerm] = useState<string | null>(null)
  const [searchUsers, { data, loading }] = useSearchContentUserSearchLazyQuery()

  const debouncedUserSearch = useDebounce((searchTerm: string) => {
    void searchUsers({ variables: { searchTerm } })
  }, 150)

  useEffect(() => {
    if (searchTerm) {
      debouncedUserSearch(searchTerm)
    }
  }, [searchTerm])

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchTerm(event.target.value)
  }

  const clearSearch = () => {
    setSearchTerm('')
  }

  return (
    <Stack padding={6} paddingTop={0} gap={{ xs: 1, md: 4 }} minWidth={{ xs: '100%', md: '550px' }}>
      <Stack
        gap={{ xs: 4, md: 8 }}
        sx={{
          zIndex: 2,
          backgroundColor: 'white',
          position: 'sticky',
          top: { xs: '58px', md: 0 },
          paddingTop: 5,
          paddingBottom: 5,
        }}
      >
        <Heading is="h3" textAlign="center" display={{ xs: 'none', md: 'initial' }}>
          <Localized id="find-new-friends-title" />
        </Heading>
        <TextField
          value={searchTerm}
          onChange={handleChange}
          placeholder={intl.findNewFriendsSearchPlaceholder()}
          autoFocus
          sx={{
            '&.MuiTextField-root': {
              borderRadius: 1,
              backgroundColor: 'limelite',
              '& .MuiOutlinedInput-notchedOutline': { borderRadius: 1, borderColor: 'mediumgray' },
            },
            '& .MuiInputBase-input::placeholder': { color: 'carbon', WebkitTextFillColor: '#666666', opacity: 1 },
          }}
          InputProps={{
            startAdornment: (
              <Box flexShrink={0} marginRight={2}>
                <Icon src={SearchIcon} size="small" color="gray" />
              </Box>
            ),
            endAdornment: (
              <Box
                flexShrink={0}
                onClick={clearSearch}
                sx={{ cursor: 'pointer', display: searchTerm ? 'initial' : 'none' }}
              >
                <Icon src={ErrorIcon} size="small" color="gray" />
              </Box>
            ),
          }}
        />
      </Stack>
      <Stack gap={{ xs: 4, md: 6 }} alignItems="center">
        {loading && <LoadingDots color="gray" />}
        {data?.userSearch.length === 0
          ? searchTerm && (
              <Text>
                <Localized id="user-search-results-not-found" params={{ searchTerm }} />
              </Text>
            )
          : data?.userSearch.map((user) => <PublicUser key={user.id} user={user} from={From.USER_SEARCH} />)}
      </Stack>
    </Stack>
  )
}

export default SearchContent
