import * as React from 'react'
import { MouseEvent } from 'react'
import Box, { BoxProps } from '@eversports/klimt-primitives/Box'

type MinWidth = Pick<BoxProps, 'minWidth'>

interface Props extends MinWidth {
  onClick: (event: MouseEvent<HTMLElement>) => void
  isActive?: boolean
}

const PickerBox = ({ isActive, onClick, minWidth = 'auto', children }: React.PropsWithChildren<Props>) => {
  return (
    <Box
      sx={{
        flex: { xs: '1 1 auto', lg: 'none' },
        border: '1px solid',
        borderColor: isActive ? 'primary.main' : 'mediumgray',
        backgroundColor: 'white',
        borderRadius: 1,
        minWidth,
        py: 2,
        px: 4,
        cursor: 'pointer',
        '&:hover': {
          borderColor: 'primary.main',
        },
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  )
}

export default PickerBox
