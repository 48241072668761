import * as React from 'react'
import { domAnimation, LazyMotion } from '@eversports/klimt-utilities/framer-motion'

import ErrorBoundary from './ErrorBoundary'
import Pages from './pages'
import PublicProfilePrivacyTermsDisclaimer from './components/PublicProfilePrivacyTermsDisclaimer'
import '@eversports/klimt-design-components/tailwind/global.css'
import './global.css'

const App = () => {
  return (
    <ErrorBoundary>
      <LazyMotion features={domAnimation}>
        <Pages />
        <PublicProfilePrivacyTermsDisclaimer />
      </LazyMotion>
    </ErrorBoundary>
  )
}

export default App
