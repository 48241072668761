import * as React from 'react'
import { useState, useEffect } from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'

const DOT_SIZE = 8
const SPACE_BETWEEN_DOTS = 6

const NUMBER_OF_DOTS_TO_SHOW = 5
const TRANSLATE_DOTS_BY = DOT_SIZE + SPACE_BETWEEN_DOTS

const NORMAL_SIZED_DOT_SCALE = 1
const MEDIUM_SIZED_DOT_SCALE = 0.83
const SMALL_SIZED_DOT_SCALE = 0.66

interface DotProps {
  index: number
  activeIndex: number
}

const getDotTransformScale = ({ index, activeIndex }: DotProps) => {
  if (Math.abs(index - activeIndex) <= 1) {
    return NORMAL_SIZED_DOT_SCALE
  }
  if (Math.abs(index - activeIndex) === 2) {
    return MEDIUM_SIZED_DOT_SCALE
  }
  return SMALL_SIZED_DOT_SCALE
}

const Dot = ({ index, activeIndex }: DotProps) => {
  const isActive = index === activeIndex
  const scale = getDotTransformScale({ index, activeIndex })
  return (
    <Box
      as="span"
      sx={{
        borderRadius: 10,
        width: `${DOT_SIZE}px`,
        height: `${DOT_SIZE}px`,
        backgroundColor: isActive ? 'white' : 'gray',
        transform: `scale(${scale})`,
      }}
    />
  )
}
interface Props {
  imageCount: number
  activeImageIndex: number
}

const Dots = ({ imageCount, activeImageIndex }: Props) => {
  const [translateX, setTranslateX] = useState(0)

  const dotsArray = Array.from(Array(imageCount))

  const numberOfDotsShown = Math.min(imageCount, NUMBER_OF_DOTS_TO_SHOW)
  const dotContainerWidth = numberOfDotsShown * (DOT_SIZE + SPACE_BETWEEN_DOTS)

  useEffect(() => {
    // The user can continously go through the image carousel
    // So if they swipe from the last image to the first, reset the translation
    if (activeImageIndex === 0) {
      setTranslateX(0)
    }

    // Or if they swipe from the first time to the last, adjust the translation to show the last dots.
    if (activeImageIndex === imageCount - 1) {
      setTranslateX((imageCount - numberOfDotsShown) * TRANSLATE_DOTS_BY * -1)
    }

    // If we're near the beginning or the end of the dots, we don't need to translateX anymore
    if (activeImageIndex <= Math.floor(numberOfDotsShown / 2)) return
    if (activeImageIndex >= imageCount - Math.floor(numberOfDotsShown / 2)) return

    // Otherwise, calculate the value for the x-axis translation.
    const translateXBy = (activeImageIndex - Math.floor(numberOfDotsShown / 2)) * -TRANSLATE_DOTS_BY

    setTranslateX(translateXBy)
  }, [activeImageIndex])

  return (
    <Box sx={{ width: '100%', bottom: 12, position: 'absolute', zIndex: 1 }}>
      <Box
        sx={{
          width: dotContainerWidth,
          overflow: 'hidden',
          borderRadius: 1,
          margin: 'auto',
        }}
      >
        <Stack
          direction="row"
          gap={`${SPACE_BETWEEN_DOTS}px`}
          sx={{
            width: 'fit-content',
            transform: `translateX(${translateX}px)`,
            transition: 'transform 0.3s cubic-bezier(0.5,0,0.5,1)',
            background: 'rgba(20, 20, 20, 0.8)',
            padding: 1,
          }}
        >
          {dotsArray.map((_, index) => (
            <Dot key={index} index={index} activeIndex={activeImageIndex} />
          ))}
        </Stack>
      </Box>
    </Box>
  )
}

export default Dots
