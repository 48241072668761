import * as React from 'react'

import { HttpStatusContext } from './http-status-context'

export interface HttpStatusObject {
  status: number | undefined
}

interface Props {
  statusObject: HttpStatusObject
}

export const HttpStatusCollector = ({ statusObject, children }: React.PropsWithChildren<Props>) => {
  const setStatus = (status: number) => {
    Object.assign(statusObject, { status })
  }

  return (
    <HttpStatusContext.Provider value={{ setStatus, status: statusObject.status }}>
      {children}
    </HttpStatusContext.Provider>
  )
}
