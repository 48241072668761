import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Divider from '@eversports/klimt-primitives/Divider'

import { Contact } from '../../VenueProfile.types'
import SectionWrapper from '../../components/SectionWrapper'

import CorporateDisclaimer from './CorporateDisclaimer'
import ContactIcons from './ContactIcons'
import { SubscribeToNewsletter } from './SubscribeToNewsletter'

interface Props {
  contact: Contact
  venueId: string
  venueName: string
}

const ContactInformation = ({ contact, venueId, venueName }: Props) => {
  return (
    <SectionWrapper sx={{ p: 4 }}>
      <Stack gap={4}>
        <ContactIcons contact={contact} />
        <Divider sx={{ borderColor: 'lightgray' }} />
        <SubscribeToNewsletter venueId={venueId} venueName={venueName} />
        <CorporateDisclaimer />
      </Stack>
    </SectionWrapper>
  )
}

export default ContactInformation
