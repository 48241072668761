import * as React from 'react'
import { useRef, useEffect, type JSX } from 'react'
import Menu from '@eversports/klimt-primitives/Menu'
import Stack, { StackProps } from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import ArrowDropDownIcon from '@eversports/design-tokens/assets/icons/arrow-drop-down.svg'
import Icon from '@eversports/klimt-primitives/Icon'
import Box from '@eversports/klimt-primitives/Box'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'

import { Localized } from '../localization/react'

type MinWidth = Pick<StackProps, 'minWidth'>

interface Props extends MinWidth {
  header: JSX.Element
  activeFilters?: Array<string>
  isDefaultOpen?: boolean
}

const FilterMenu = ({
  header,
  activeFilters,
  minWidth,
  isDefaultOpen = false,
  children,
}: React.PropsWithChildren<Props>) => {
  const containerRef = useRef(null)
  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClick = () => {
    if (containerRef.current) {
      setAnchorEl(containerRef.current)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const firstActiveFilter = activeFilters?.length ? activeFilters[0] : undefined
  const numberOfActiveFilters = activeFilters?.length
  const remainingNumberOfActiveFilters = activeFilters?.length ? activeFilters.length - 1 : undefined

  useEffect(() => {
    if (isDefaultOpen) {
      handleClick()
    }
  }, [isDefaultOpen])

  if (isMobile) {
    return null
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        backgroundColor="white"
        forwardedRef={containerRef}
        onClick={handleClick}
        gap={2}
        sx={{
          border: '1px solid',
          borderColor: anchorEl ? 'primary.main' : 'mediumgray',
          boxSizing: 'border-box',
          borderRadius: 1,
          minWidth,
          py: 2,
          px: 5,
          cursor: 'pointer',
          '&:hover': {
            borderColor: 'primary.main',
          },
        }}
      >
        <Text variant="small">{header}</Text>
        {/* On small laptop screens, dont show the name of the first filter */}
        <Stack display={{ xs: 'flex', lg: 'none', xl: 'flex' }} gap={1} flexDirection="row" alignItems="center">
          {firstActiveFilter && (
            <Text variant="small" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
              <Localized id={firstActiveFilter as any} />
            </Text>
          )}
          {Boolean(remainingNumberOfActiveFilters) && (
            <Text sx={{ color: 'primary.main', fontWeight: 'bold' }}>+{remainingNumberOfActiveFilters}</Text>
          )}
        </Stack>
        {Boolean(numberOfActiveFilters) && (
          <Box display={{ xs: 'none', lg: 'block', xl: 'none' }}>
            <Text sx={{ color: 'primary.main', fontWeight: 'bold' }}>+{numberOfActiveFilters}</Text>
          </Box>
        )}
        <Box
          style={{
            transform: anchorEl ? 'rotate(-180deg)' : 'none',
            transition: 'transform 293ms cubic-bezier(0.3, 0, 0.4, 1)',
          }}
        >
          <Icon src={ArrowDropDownIcon} size="default" color={anchorEl ? 'primary' : 'gray'} />
        </Box>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          '& .MuiMenu-paper': {
            border: '0.5px solid',
            borderColor: 'lightgray',
            boxShadow: '0px 4px 12px rgba(20, 20, 20, 0.2);',
            borderRadius: '0px 8px 8px 8px',
            padding: 4,
            maxHeight: '235px',
          },
          '& .MuiMenu-list': {
            padding: 0,
          },
        }}
      >
        {children}
      </Menu>
    </>
  )
}

export default FilterMenu
