import * as React from 'react'
import Avatar from '@eversports/klimt-primitives/Avatar'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Checkbox from '@eversports/klimt-forms/Checkbox'
import EversportsLogoIcon from '@eversports/design-tokens/assets/logo/icon.svg'

import { PublicUser } from './InviteFriends.types'
import { FRIENDS_TO_INVITE_FORM_KEY } from './InviteFriends.constants'

interface Props {
  friend: PublicUser
}

const Friend = ({ friend }: Props) => {
  return (
    <Checkbox
      value={friend.id}
      name={FRIENDS_TO_INVITE_FORM_KEY}
      label={
        <Stack direction="row" alignItems="center" width="100%" paddingY={1.5} gap={2} is="span">
          <Avatar
            src={friend.profilePicture?.small || EversportsLogoIcon}
            sx={{
              height: '32px',
              width: '32px',
              border: '1px solid',
              borderColor: 'lightgray',
              backgroundColor: 'white',
              borderRadius: 10,
            }}
          />
          <Stack gap={1} sx={{ maxWidth: 'fit-content' }}>
            <Text
              sx={{
                color: 'midnight',
                fontWeight: 'bold',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {friend.firstName} {friend.lastName}
            </Text>
            <Text variant="small" sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
              @{friend.handle}
            </Text>
          </Stack>
        </Stack>
      }
    />
  )
}

export default Friend
