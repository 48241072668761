import * as React from 'react'
import { useMemo } from 'react'
import SeoLink from '@eversports/klimt-primitives/SeoLink'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'

import { SportWithParents } from '../Discover.types'
import { DiscoverPrefix } from '../../../App.types'

import { SportSlugIconMapping } from './SportPill.constants'

// most of the popular "main" sports have an icon
// but for some of the subcateogries, such as Aerial Yoga, we need to check for a parent's icon
// this logic may change as we add more icons
const getSportIcon = (sport: SportWithParents) => {
  const sportIcon = SportSlugIconMapping[sport.slug]

  if (sportIcon) {
    return sportIcon
  }

  const parentsWithIcons = sport.parents.filter((parent) => SportSlugIconMapping[parent.slug])

  if (parentsWithIcons.length) {
    return SportSlugIconMapping[parentsWithIcons[0].slug]
  }

  return null
}

interface Props {
  sport: SportWithParents
  citySlug: string
  iconLoadMethod: 'eager' | 'lazy' // tried to find TS for this - no luck, maybe investigate again in the future
}

const SportPill = ({ sport, citySlug, iconLoadMethod }: Props) => {
  const to = `${DiscoverPrefix.APP_SPORT_CITY}/${sport.slug}/${citySlug}`
  const crawlerLink = `${DiscoverPrefix.SEO_SPORT_CITY}/${sport.slug}/${citySlug}`
  const ariaLabel = `${sport.name} in ${citySlug}`

  const sportIcon = useMemo(() => getSportIcon(sport), [sport])

  return (
    <SeoLink encodedTo={btoa(to)} type="crawler" crawlerLink={crawlerLink} ariaLabel={ariaLabel} external>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          px: 5,
          py: 2,
          gap: 2,
          textTransform: 'capitalize',
          cursor: 'pointer',
          border: '1px solid',
          borderColor: 'mediumgray',
          borderRadius: 1,
          flexShrink: 0,
          '&:hover': {
            borderColor: 'primary.main',
          },
        }}
      >
        {sportIcon && <img src={sportIcon} width="14px" height="14px" loading={iconLoadMethod} alt={sport.name} />}
        <Text>{sport.name}</Text>
      </Stack>
    </SeoLink>
  )
}

export default SportPill
