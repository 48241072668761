import * as React from 'react'
import { useEffect } from 'react'
import { AnimatePresence } from '@eversports/klimt-utilities/framer-motion'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import Header from '../../components/Header'
import Search from '../../components/Search'

import Profile from './pages/Profile'
import Routes from './PublicUserProfile.routes'
import Friends from './pages/Friends'

const PublicUserProfilePage = () => {
  const { path, isExact } = useRouteMatch()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Header position={{ xs: 'relative', lg: 'sticky' }} boxShadow={!isExact ? 'none' : undefined}>
        <Search />
      </Header>
      <AnimatePresence mode="wait">
        <Switch>
          <Route exact path={path}>
            <Profile />
          </Route>
          <Route path={`${path}${Routes.FRIENDS}`}>
            <Friends />
          </Route>
        </Switch>
      </AnimatePresence>
    </>
  )
}

export default PublicUserProfilePage
