import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Svg from '@eversports/klimt-primitives/Svg'
import Text from '@eversports/klimt-primitives/Text'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import NoSsr from '@eversports/klimt-design-components/NoSsr'
import Skeleton from '@eversports/klimt-primitives/Skeleton/Skeleton'

import { EventSession, LocationType } from '../../ActivityDetails.types'
import { Localized } from '../../../../localization/react'
import StaticMap from '../../../../components/Map/StaticMap'
import { formatLocation } from '../../helpers/format-location'

import LocationIcon from './assets/location.svg'
import OnlineStreamInfo from './OnlineStreamInfo'

const MOBILE_MAP_WIDTH = 420
const TABLET_MAP_WIDTH = 500
const DESKTOP_MAP_WIDTH = 670
const MAP_HEIGHT = 120

interface Props {
  locations: Array<LocationType>
  eventSessions: Array<EventSession>
}

const Location = ({ locations, eventSessions }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'))

  const hasMultipleLocations = locations.length > 1

  if (hasMultipleLocations) {
    return (
      <Stack gap={1}>
        <Stack direction="row" alignItems="center" flexWrap="wrap" columnGap={2} rowGap={1}>
          <Svg src={LocationIcon} width={12} height={12} />
          <Text color="midnight" sx={{ fontWeight: 'bold' }}>
            <Localized id="activity-details-location-header" />
          </Text>
        </Stack>
        <Text color="midnight">
          <Localized id="activity-details-multiple-locations" />
        </Text>
      </Stack>
    )
  }

  const location = locations[0]
  const { hasOnlineStream, onlineStream } = eventSessions[0]
  const streamProvider = onlineStream?.provider?.key

  if (hasOnlineStream && streamProvider) {
    return (
      <Stack gap={4}>
        <Stack gap={1}>
          <Stack direction="row" alignItems="center" flexWrap="wrap" columnGap={2} rowGap={1}>
            <Svg src={LocationIcon} width={12} height={12} />
            <Text color="midnight" sx={{ fontWeight: 'bold' }}>
              <Localized id="activity-details-location-header" />
            </Text>
          </Stack>
          <Text color="midnight">
            <Localized id="activity-details-online-location" />
          </Text>
        </Stack>
        <OnlineStreamInfo streamProvider={streamProvider} />
      </Stack>
    )
  }

  if (!location || !location.latitude || !location.longitude) {
    return null
  }

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <Stack direction="row" alignItems="center" flexWrap="wrap" columnGap={2} rowGap={1}>
          <Svg src={LocationIcon} width={12} height={12} />
          <Text color="midnight" sx={{ fontWeight: 'bold' }}>
            <Localized id="activity-details-location-header" />
          </Text>
        </Stack>
        <Text color="midnight">{formatLocation(location)}</Text>
      </Stack>
      <NoSsr fallback={<Skeleton variant="rectangular" width="100%" height={MAP_HEIGHT} sx={{ borderRadius: 1 }} />}>
        <StaticMap
          latitude={location.latitude}
          longitude={location.longitude}
          width={isMobile ? MOBILE_MAP_WIDTH : isTablet ? TABLET_MAP_WIDTH : DESKTOP_MAP_WIDTH}
          height={MAP_HEIGHT}
          zoomLevel={15}
          scale={2}
          showOpenGoogleMaps
        />
      </NoSsr>
    </Stack>
  )
}

export default Location
