import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'
import { useHttpStatus } from '@eversports/react-app-base/http-status/use-http-status'
import { extractStatusCodeFromError } from '@eversports/react-app-base/create-apollo-client'

import { useDiscoverCityDiscoverContextByCityQuery, useDiscoverCityVenueSearchLazyQuery } from '../../../../graphql'
import { useDiscoverReducer, useDiscoverState } from '../../DiscoverContext'
import { getBoundingBoxFromQueryResult } from '../../helpers/get-bounding-box-from-query-result'
import { NUMBER_OF_LISTING_RESULTS_TO_LOAD } from '../../Discover.constants'
import setSearchParams from '../../../../utils/set-search-params'
import LoadingPage from '../../../../components/LoadingPage'
import NotFound from '../NotFound'

import DiscoverCityComponent from './DiscoverCityComponent'

const DiscoverCity = () => {
  const dispatch = useDiscoverReducer()
  const { venueAmenitiesActiveFilters, venueAttributeActiveFilters, initialSearchBoundingBox } = useDiscoverState()
  const { amplitude, logAmplitudeEventOnce } = useLogAmplitudeEventOnce()
  const { citySlug } = useParams<{ citySlug: string }>()
  const { setStatus, status } = useHttpStatus()

  const {
    data: initialData,
    loading,
    error,
  } = useDiscoverCityDiscoverContextByCityQuery({
    variables: {
      citySlug,
    },
  })

  const [discoverCityVenueSearchLazyQuery, { data: venueSearchData }] = useDiscoverCityVenueSearchLazyQuery()

  useEffect(() => {
    if (!initialData) return
    const { northEast, southWest } = getBoundingBoxFromQueryResult(initialData.discoverContextByCity.boundingBox)
    dispatch({
      type: 'SET_INITIAL_SEARCH_BOUNDING_BOX',
      payload: {
        northEast,
        southWest,
      },
    })
  }, [initialData])

  useEffect(() => {
    if (!initialSearchBoundingBox || !initialData?.discoverContextByCity) return
    void discoverCityVenueSearchLazyQuery({
      variables: {
        city: initialData?.discoverContextByCity.city.id,
        filters: {
          boundingBox: initialSearchBoundingBox,
          venueAmenities: venueAmenitiesActiveFilters,
          venueAttributes: venueAttributeActiveFilters,
        },
      },
    })
  }, [venueAmenitiesActiveFilters, venueAttributeActiveFilters, initialSearchBoundingBox, initialData])

  useEffect(() => {
    setSearchParams({ venueAmenitiesActiveFilters, venueAttributeActiveFilters })
  }, [venueAmenitiesActiveFilters, venueAttributeActiveFilters])

  useEffect(() => {
    if (!amplitude || !venueSearchData) return

    const totalNumberOfResults = venueSearchData?.venueSearch?.edges.length ?? 0
    const numberOfInitialResults = Math.min(totalNumberOfResults, NUMBER_OF_LISTING_RESULTS_TO_LOAD)

    logAmplitudeEventOnce({
      eventName: 'Viewed Discovery City',
      eventProperties: {
        city: citySlug,
        numberOfInitialResults,
      },
    })
  }, [amplitude, venueSearchData])

  const onlineVenues = useMemo(
    () => initialData?.discoverContextByCity?.onlineVenues.edges.map((edge) => edge.node),
    [initialData],
  )

  const venueSearchResults = useMemo(() => {
    return venueSearchData?.venueSearch.edges
      .map((edge) => edge.node)
      .filter((venue) => venue.location.latitude && venue.location.longitude)
  }, [venueSearchData])

  if (status === 404) {
    return <NotFound />
  }

  if (error) {
    const status = extractStatusCodeFromError(error)
    if (status) {
      setStatus(status)
    }
    if (status === 404) {
      return <NotFound />
    }
  }

  if (!initialData || loading) {
    return <LoadingPage />
  }

  const { content, meta, bookableSports } = initialData.discoverContextByCity

  if (!meta || !content) {
    throw new Error('Missing the meta or content information for the Discover City page')
  }

  return (
    <DiscoverCityComponent
      meta={meta}
      content={content}
      onlineVenues={onlineVenues}
      listingResults={venueSearchResults}
      bookableSports={bookableSports}
      citySlug={citySlug}
    />
  )
}

export default DiscoverCity
