import * as React from 'react'
import { useState, useEffect, ChangeEvent } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'
import TextField from '@eversports/klimt-primitives/TextField'
import Icon from '@eversports/klimt-primitives/Icon'
import Close from '@eversports/design-tokens/assets/icons/close.svg'
import { useParams } from 'react-router-dom'
import SeoLink from '@eversports/klimt-primitives/SeoLink'

import { useIntl } from '../../../localization/react'
import { DiscoverPrefix, Sport } from '../../../App.types'

import { MOBILE_DRAWER_HEIGHT } from './AllSportsList'

const DRAWER_CONTENT_HEIGHT_OFFSET = '140px'

interface Props {
  sports: Array<Sport>
  isShown: boolean
}

const AllSportsContent = ({ sports, isShown }: Props) => {
  const [searchedSport, setSearchSport] = useState('')

  const shownSports = sports.filter((sport) => sport.name.toLowerCase().includes(searchedSport.toLowerCase()))

  const { citySlug } = useParams<{ citySlug: string }>()
  const intl = useIntl()

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchSport(e.target.value)
  }

  const clearInput = () => {
    setSearchSport('')
  }

  useEffect(() => {
    clearInput()
  }, [isShown])

  return (
    <Stack gap={2}>
      <TextField
        id="sport-search"
        value={searchedSport}
        onChange={handleInputChange}
        placeholder={intl.searchForSport()}
        sx={{
          width: '100%',
          borderRadius: 1,
          backgroundColor: 'white',
          position: 'sticky',
          top: 0,
          '& .MuiInputBase-input': {
            padding: '8px 12px',
          },
        }}
        InputProps={{
          endAdornment: searchedSport ? (
            <Box sx={{ cursor: 'pointer' }}>
              <Icon onClick={clearInput} src={Close} color="gray" size="default" />
            </Box>
          ) : null,
        }}
      />
      <Stack
        sx={{
          overflowY: 'scroll',
          height: { xs: `calc(${MOBILE_DRAWER_HEIGHT} - ${DRAWER_CONTENT_HEIGHT_OFFSET})`, sm: 'inherit' },
        }}
      >
        {shownSports.map((sport) => (
          <SeoLink
            key={`${sport.slug}_${citySlug}`}
            encodedTo={btoa(`${DiscoverPrefix.APP_SPORT_CITY}/${sport.slug}/${citySlug}`)}
            type="crawler"
            crawlerLink={`${DiscoverPrefix.SEO_SPORT_CITY}/${sport.slug}/${citySlug}`}
          >
            <Text
              sx={{
                px: 3,
                py: 3,
                borderBottom: '1px solid',
                borderColor: 'mediumgray',
                textTransform: 'capitalize',
                '&: hover': {
                  backgroundColor: 'lightgray',
                },
              }}
            >
              {sport.name}
            </Text>
          </SeoLink>
        ))}
      </Stack>
    </Stack>
  )
}

export default AllSportsContent
