import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import EversportsLogoIcon from '@eversports/design-tokens/assets/logo/icon.svg'

import DefaultUserPicture from './assets/default-user-picture.webp'
import { cacheBurstImage } from './utils/cache-burst-image'

interface Props {
  variant: 'small' | 'medium' | 'large'
  type?: 'person' | 'venue' | 'sport'
  src?: string
  fallback?: string
}

const VARIANTS = {
  small: {
    width: '33px',
    height: '33px',
    borderRadius: 2,
    border: '1px solid',
    borderColor: 'mediumgray',
  },
  medium: {
    width: '56px',
    height: '56px',
    borderRadius: 20,
    border: '2px solid',
    borderColor: 'mediumgray',
    boxShadow: boxShadows.default,
  },
  large: {
    width: { xs: '100px', md: '240px' },
    height: { xs: '100px', md: '240px' },
    borderRadius: 20,
    border: '4px solid',
    borderColor: 'mediumgray',
    boxShadow: boxShadows.hover,
  },
}

const BaseRecapSectionImage = ({ src, variant, fallback }: Props) => {
  return (
    <Box
      component="img"
      loading="lazy"
      src={src ? cacheBurstImage(src) : fallback}
      sx={{
        ...VARIANTS[variant],
        flexShrink: 0,
        backgroundColor: 'white',
        objectFit: 'cover',
      }}
    />
  )
}

const RecapSectionVenueImage = ({ src, variant }: Props) => {
  return <BaseRecapSectionImage variant={variant} src={src} fallback={EversportsLogoIcon} />
}

const RecapSectionPersonImage = ({ src, variant }: Props) => {
  return <BaseRecapSectionImage variant={variant} src={src} fallback={DefaultUserPicture} />
}

export const RecapSectionImage = ({ type, src, variant }: Props) => {
  if (type === 'venue') {
    return <RecapSectionVenueImage src={src} variant={variant} />
  }

  if (type === 'person') {
    return <RecapSectionPersonImage src={src} variant={variant} />
  }

  return null
}
