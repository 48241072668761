import * as React from 'react'
import { DotLottieReact } from '@lottiefiles/dotlottie-react'

interface Props {
  src: string
  autoplay?: boolean
  loop?: boolean
  style?: React.CSSProperties
}

const Player = ({ ...props }: Props) => {
  return <DotLottieReact {...props} />
}

export default Player
