import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Button from '@eversports/klimt-primitives/Button'
import Processing from '@eversports/klimt-primitives/Processing'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { Localized } from '../../../../../../../localization/react'
import { useApproveFriendRequestAcceptFriendRequestMutation } from '../../../../../../../graphql'

interface Props {
  friendId: string
}

const ApproveFriendRequest = ({ friendId }: Props) => {
  const { amplitude } = useAmplitude()
  const [approveFriendship, { loading }] = useApproveFriendRequestAcceptFriendRequestMutation({
    variables: { userId: friendId },
  })

  const handleClick = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Approve Received Friend Request Button')
    }
    void approveFriendship()
  }

  return (
    <Processing isLoading={loading}>
      <Button
        variant="tertiary"
        sx={{ textTransform: 'none', paddingY: 1, paddingX: 2, width: 'fit-content' }}
        onClick={handleClick}
      >
        <Text sx={{ color: 'midnight', fontWeight: 'bold' }}>
          <Localized id="approve-friend-request" />
        </Text>
      </Button>
    </Processing>
  )
}

export default ApproveFriendRequest
