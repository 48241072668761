import * as React from 'react'
import { motion } from '@eversports/klimt-utilities/framer-motion'
import Stack from '@eversports/klimt-primitives/Stack'
import FilledHorizontalLogo from '@eversports/design-tokens/assets/logo/filled-inverted-horizontal.svg'
import useInView from '@eversports/klimt-utilities/use-intersection-observer'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'
import { domToBlob } from 'modern-screenshot'
import mergeRefs from 'react-merge-refs'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import ClientLogger from '@eversports/klimt-utilities/client-logger'
import { useState } from 'react'

import { Localized } from '../../localization/react'
import { RECAP_YEAR } from '../../App.constants'

import RecapOverviewItem, { RecapOverviewVariant } from './RecapOverviewItem'
import { Recap } from './YearlyRecap.types'
import RecapOverviewSharingButton from './RecapOverviewSharingButton'
import SharingOverviewVideo from './assets/sharing-overview-video.mp4'

interface Props {
  recap: Recap
}

const getOverviewItems = (recap: Recap): Array<RecapOverviewVariant> => {
  const hasFriends = recap.friends.length >= 3
  const hasTeachers = recap.trainers.length >= 3

  if (hasFriends && hasTeachers) {
    return ['top-sport', 'total-days', 'top-venue', 'top-teacher', 'top-friend']
  }

  if (hasTeachers && !hasFriends) {
    return ['top-sport', 'total-sports', 'total-days', 'top-venue', 'top-teacher']
  }

  if (hasFriends && !hasTeachers) {
    return ['top-sport', 'total-sports', 'total-days', 'top-venue', 'top-friend']
  }

  return ['top-sport', 'total-sports', 'total-days', 'streak', 'top-venue']
}

const RecapOverview = ({ recap }: Props) => {
  const [isGeneratingImage, setIsGeneratingImage] = useState(false)
  const { amplitude, logAmplitudeEventOnce } = useLogAmplitudeEventOnce()
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  }

  const handleClick = async (_e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!containerRef.current) return

    setIsGeneratingImage(true)

    if (amplitude) {
      logAmplitudeEventOnce({
        eventName: `Clicked ${RECAP_YEAR} Yearly Recap Share CTA Button`,
        eventProperties: {},
      })
    }

    try {
      const blob = await domToBlob(containerRef.current, {
        quality: 1,
        scale: 2,
        filter: (node) => {
          return !(node instanceof Element && node.getAttribute('data-exclude'))
        },
      })

      if (!blob) {
        throw new Error('Failed to generate overview screenshot blob.')
      }

      const data = {
        title: 'Eversports 2024 Recap',
        files: [new File([blob], 'eversports-2024-recap.png', { type: 'image/png' })],
      }

      if (navigator.canShare(data) && navigator.share) {
        await navigator.share(data)
      } else {
        // Fallback: Trigger download instead (for browsers without Web Share API support)
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'eversports-recap.png'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(url)
      }
    } catch (error) {
      ClientLogger.warning('Failed to generate recap image for sharing:', error as Error)
    } finally {
      setIsGeneratingImage(false)
    }
  }

  const items = getOverviewItems(recap)

  return (
    <motion.div
      ref={mergeRefs([ref, containerRef])}
      variants={containerVariants}
      initial="hidden"
      className="relative min-h-dvh w-full overflow-hidden"
      animate={inView ? 'visible' : 'hidden'}
      style={{ scrollSnapAlign: 'start', scrollSnapStop: 'always' }}
    >
      <video
        autoPlay
        muted
        loop
        playsInline
        className="absolute left-0 top-0 z-0 size-full object-cover"
        crossOrigin="anonymous"
      >
        <source src={SharingOverviewVideo} type="video/mp4" />
      </video>
      <div className="relative z-10 min-h-dvh bg-black bg-opacity-50">
        <Stack
          gap={6}
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          width="100%"
          minHeight="100dvh"
          sx={{
            background: 'linear-gradient(180deg, rgba(25, 65, 72, 0.70) 0%, rgba(25, 65, 72, 0.00) 100%)',
          }}
        >
          <Stack direction="column" gap={2} alignItems="center">
            <img width="240px" height="48px" src={FilledHorizontalLogo} loading="lazy" />
            <Heading
              is="h5"
              variant="xlarge"
              sx={{
                color: 'white',
                display: 'flex',
                gap: 2,
                fontFamily: "'Bricolage Grotesque', 'Adjusted Bricolage Grotesque Fallback'",
                textShadow: '0px 2px 4px rgba(0, 0, 0, 0.50)',
                fontWeight: 500,
              }}
            >
              <Localized
                id="yearly-recap-overview-title"
                params={{
                  year: (
                    <Text
                      component="span"
                      sx={{
                        fontSize: 26,
                        lineHeight: '39px',
                        fontFamily: "'Bricolage Grotesque', 'Adjusted Bricolage Grotesque Fallback'",
                        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.50)',
                        fontWeight: 500,
                        color: '#0DCFBB',
                      }}
                    >
                      {RECAP_YEAR}
                    </Text>
                  ),
                }}
              />
            </Heading>
          </Stack>
          <Stack gap={{ xs: 3, md: 6 }} paddingTop={{ xs: 2, md: 4 }} paddingX={{ xs: 2, md: 4 }}>
            {items.map((item) => (
              <motion.div variants={itemVariants} key={item}>
                <RecapOverviewItem variant={item} recap={recap} />
              </motion.div>
            ))}
          </Stack>
          <motion.div variants={itemVariants} className="w-full">
            <RecapOverviewSharingButton onClick={(e) => void handleClick(e)} isLoading={isGeneratingImage} />
          </motion.div>
        </Stack>
      </div>
    </motion.div>
  )
}

export default RecapOverview
