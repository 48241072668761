import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Sticky from '@eversports/klimt-primitives/Sticky'

import { Venue } from '../VenueProfile.types'

import ContactInformation from './ContactInformation'
import CallToAction from './CallToAction'

interface Props {
  venue: Venue
  headerOffset: number
}

const MARGIN_OFFSET = 16

const DesktopSidebar = ({ venue, headerOffset }: Props) => {
  return (
    <div style={{ height: '100%' }}>
      <Sticky boundaryEdge="top" offset={headerOffset + MARGIN_OFFSET}>
        <Stack gap={2} sx={{ display: { xs: 'none', lg: 'flex' } }}>
          <CallToAction
            cheapestPrice={venue.cheapestPrice}
            offerings={venue.offerings}
            cheapestTrialProductPrice={venue.cheapestTrialProductPrice}
            slug={venue.slug}
          />
          <ContactInformation contact={venue.contact} venueId={venue.id} venueName={venue.name} />
        </Stack>
      </Sticky>
    </div>
  )
}

export default DesktopSidebar
